import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from '../../Componentes/Navbar/Navbar';
import ResultadoPoliticaDeSeguranca from '../../Componentes/ResultadoPoliticaDeSeguranca';
import ResultadoTestePoliticaDeSeguranca from '../../Componentes/ResultadoTestePoliticaDeSeguranca';

const Container = styled.div`
  margin: 20px auto;
  /* max-width: 800px; */
  background-color: var(--branco);
  /* height: 100vh; */
  margin-bottom: 80px;
  padding-bottom: 40px;
`;

const TestePoliticaDeSeguranca = () => {
  const { state } = useLocation();
  const [dados, setDados] = useState(null);
  const targetRef = useRef();

  const getDados = async () => {
    try {
      setDados(state);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDados();
  }, []);

  return (
    <div>
      <Navbar />
      <div ref={targetRef}>
        <Container>
          {dados && <ResultadoTestePoliticaDeSeguranca dados={dados} />}
        </Container>
      </div>
    </div>
  );
};

export default TestePoliticaDeSeguranca;
