import React, { useState } from "react";
import Navbar from "../../Componentes/Navbar/Navbar";
import styled from "styled-components";
import CampoCheckBox from "../../Componentes/CampoCheckBox";
import { perguntasRelatorioDeImpacto as perguntas } from "../../Dados/PerguntasRelatorioDeImpacto";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../Firebase/firebase";
import { useNavigate } from "react-router-dom";

const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
  @media screen and (max-width: 960px) {
    margin: 2% 4%;
    padding: 3%;
  }
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;

const RelatorioDeImpacto = () => {
  const navigate = useNavigate();
  const docRef = collection(db, "usuarios");
  const [arrayDeRespostas, setArrayDeRespostas] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const adicionaResposta = (posicao) => {
    console.log(arrayDeRespostas);
    const updatedCheckedState = arrayDeRespostas.map((item, index) =>
      index === posicao ? !item : item
    );

    setArrayDeRespostas(updatedCheckedState);
  };

  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: arrayDeRespostas[0] ? "Sim" : "Não",
          [perguntas[1].codigo]: arrayDeRespostas[1] ? "Sim" : "Não",
          [perguntas[2].codigo]: arrayDeRespostas[2] ? "Sim" : "Não",
          [perguntas[3].codigo]: arrayDeRespostas[3] ? "Sim" : "Não",
          [perguntas[4].codigo]: arrayDeRespostas[4] ? "Sim" : "Não",
          [perguntas[5].codigo]: arrayDeRespostas[5] ? "Sim" : "Não",
          [perguntas[6].codigo]: arrayDeRespostas[6] ? "Sim" : "Não",
          [perguntas[7].codigo]: arrayDeRespostas[7] ? "Sim" : "Não",
          [perguntas[8].codigo]: arrayDeRespostas[8] ? "Sim" : "Não",
          [perguntas[9].codigo]: arrayDeRespostas[9] ? "Sim" : "Não",
          [perguntas[10].codigo]: arrayDeRespostas[10] ? "Sim" : "Não",
          [perguntas[11].codigo]: arrayDeRespostas[11] ? "Sim" : "Não",
          [perguntas[12].codigo]: arrayDeRespostas[12] ? "Sim" : "Não",

          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      navigate("/documento-relatorio-impacto", {
        state: getAuth().currentUser.uid,
      });
      // navigate('/documento');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <Title>Relatório De Impacto</Title>
      <form onSubmit={cadastrar}>
        <NeumorphismContainer>
          <SectionTitle>Introdução</SectionTitle>
          <p>
            Este Relatório de Impacto à Proteção de Dados (RIPD) é elaborado com
            base na Lei Geral de Proteção de Dados (LGPD), com o objetivo de
            identificar e avaliar os riscos para a privacidade dos titulares de
            dados pessoais no contexto das atividades da empresa.
          </p>

          <SectionSubTitle>
            O escopo deste RIPD abrange todas as atividades da empresa que
            envolvem o tratamento de dados pessoais, incluindo:
          </SectionSubTitle>
          {perguntas.map((item, index) => {
            if (index >= 0 && index < 4) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}
        </NeumorphismContainer>
        <NeumorphismContainer>
          <SectionTitle>Identificação de dados pessoais</SectionTitle>
          <p>
            Foram identificados os seguintes tipos de dados pessoais tratados
            pela empresa:
          </p>
          {perguntas.map((item, index) => {
            if (index >= 4 && index < 9) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}
        </NeumorphismContainer>

        <NeumorphismContainer>
          <SectionTitle>Medidas de Mitigação de Riscos</SectionTitle>
          <p>A empresa adotou as seguintes medidas de mitigação de riscos:</p>
          {perguntas.map((item, index) => {
            if (index >= 9 && index < 13) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}
        </NeumorphismContainer>
        <ButtonContainer>
          <ButtonPattern>Enviar</ButtonPattern>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default RelatorioDeImpacto;
