import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  border: 8px solid #ddd;
  border-top: 8px solid #a402e1;
  border-bottom: 8px solid #a402e1;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ModalText = styled.div`
  margin-left: 14px;
  font-size: 24px;
`;

const Loading = (props) => {
  return (
    <Container
      style={{ display: props.isLoading ? 'flex' : 'none' }}
      className='modal'
    >
      <ModalContent>
        <Loader />
        <ModalText>Enviando...</ModalText>
      </ModalContent>
    </Container>
  );
};

export default Loading;
