export const MenuItems = [
  {
    title: 'Mapeamento de dados',
    path: '/mapeamento-de-dados',
    cName: 'dropdown-link',
  },
  {
    title: 'Matriz de risco',
    path: '/matriz-de-risco',
    cName: 'dropdown-link',
  },
  {
    title: 'Tabela de descarte',
    path: '/tabela-de-descarte',
    cName: 'dropdown-link',
    video: 'https://www.youtube.com/embed/BMCVOqkUF4Q?si=_xyHpNH4YWvcGKiV',
  },
  {
    title: 'Controles técnicos',
    path: '/controles-técnicos',
    cName: 'dropdown-link',
    video: 'https://www.youtube.com/embed/VmPAkyMap60?si=bN9Ntnn2XRiT6lS4',
  },
  {
    title: 'Incidente de segurança',
    path: '/incidente-de-segurança',
    cName: 'dropdown-link',
    video: 'https://www.youtube.com/embed/uU-tCZ1aTVg?si=FDVgac4nJa44AVen',
  },
];
