import React, { useState } from "react";
import Navbar from "../../Componentes/Navbar/Navbar";
import styled from "styled-components";
import CampoCheckBox from "../../Componentes/CampoCheckBox";
import { PerguntasAditivoDeContratoClt as perguntas } from "../../Dados/PerguntasAditivoDeContratoClt";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import CampoTexto from "../../Componentes/CampoTexto";

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;
const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
  @media screen and (max-width: 960px) {
    margin: 2% 4%;
    padding: 3%;
  }
`;

const AditivoDeContratoClt = () => {
  const navigate = useNavigate();
  const docRef = collection(db, "usuarios");
  const [pergunta0, setPergunta0] = useState();
  const [pergunta1, setPergunta1] = useState();
  const [pergunta2, setPergunta2] = useState();
  const [pergunta3, setPergunta3] = useState();
  const [pergunta4, setPergunta4] = useState();
  const [arrayDeRespostas, setArrayDeRespostas] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const adicionaResposta = (posicao) => {
    console.log(arrayDeRespostas);
    const updatedCheckedState = arrayDeRespostas.map((item, index) =>
      index === posicao ? !item : item
    );

    setArrayDeRespostas(updatedCheckedState);
  };

  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: pergunta0,
          [perguntas[1].codigo]: pergunta1,
          [perguntas[2].codigo]: pergunta2,
          [perguntas[3].codigo]: pergunta3,
          [perguntas[4].codigo]: pergunta4,
          [perguntas[5].codigo]: arrayDeRespostas[5] ? "Sim" : "Não",
          [perguntas[6].codigo]: arrayDeRespostas[6] ? "Sim" : "Não",
          [perguntas[7].codigo]: arrayDeRespostas[7] ? "Sim" : "Não",
          [perguntas[8].codigo]: arrayDeRespostas[8] ? "Sim" : "Não",
          [perguntas[9].codigo]: arrayDeRespostas[9] ? "Sim" : "Não",
          [perguntas[10].codigo]: arrayDeRespostas[10] ? "Sim" : "Não",
          [perguntas[11].codigo]: arrayDeRespostas[11] ? "Sim" : "Não",
          [perguntas[12].codigo]: arrayDeRespostas[12] ? "Sim" : "Não",
          [perguntas[13].codigo]: arrayDeRespostas[13] ? "Sim" : "Não",
          [perguntas[14].codigo]: arrayDeRespostas[14] ? "Sim" : "Não",
          [perguntas[15].codigo]: arrayDeRespostas[15] ? "Sim" : "Não",
          [perguntas[16].codigo]: arrayDeRespostas[16] ? "Sim" : "Não",
          [perguntas[17].codigo]: arrayDeRespostas[17] ? "Sim" : "Não",
          [perguntas[18].codigo]: arrayDeRespostas[18] ? "Sim" : "Não",

          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      navigate("/documento-aditivo-clt", { state: getAuth().currentUser.uid });
      // navigate('/documento');
      console.log("Cadastrar");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <Title>Aditivo de Contrato - Funcionário CLT</Title>
      <form onSubmit={cadastrar}>
        <NeumorphismContainer>
          <SectionTitle>Preenchimento do contrato</SectionTitle>
          <p>
            Para o preenchimento automático do termo de consentimento dos
            funcionários em relação à LGPD, estas são as informações que
            precisamos que você forneça:
          </p>
          <CampoTexto
            required={true}
            label={perguntas[0].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta0(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[1].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta1(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[2].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta2(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[3].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta3(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[4].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta4(e.target.value)}
          />
          {perguntas.map((item, index) => {
            if (index >= 5 && index < 19) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={() => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}
        </NeumorphismContainer>
        <ButtonContainer>
          <ButtonPattern>Enviar</ButtonPattern>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default AditivoDeContratoClt;
