import React, { useEffect, useState } from 'react';
import { Button } from './Button';
import logo from './BRANCO 2.png';
import { Link, Navigate } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';
import Dropdown2 from './Dropdown2';
import Dropdown3 from './Dropdown3';
import { signOut } from 'firebase/auth';
import { auth } from '../../Firebase/firebase';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropDownClick, setdropDownClick] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onMouseEnter = (dropdownId) => {
    if (window.innerWidth > 960) {
      if (dropdownId === 'Segurança da informação') {
        setDropdown(true);
      } else if (dropdownId === 'Jurídico') {
        setDropdown2(true);
      } else if (dropdownId === 'Adequação do site') {
        setDropdown3(true);
      }
    }
  };

  const onMouseLeave = (dropdownId) => {
    if (window.innerWidth > 960) {
      if (dropdownId === 'Segurança da informação') {
        setDropdown(false);
      } else if (dropdownId === 'Jurídico') {
        setDropdown2(false);
      } else if (dropdownId === 'Adequação do site') {
        setDropdown3(false);
      }
    }
  };

  const onClick = (dropdownId) => {
    setdropDownClick(!dropDownClick);
    if (window.innerWidth < 960) {
      if (dropdownId === 'Segurança da informação') {
        if (dropDownClick) {
          setDropdown(true);
        } else {
          setDropdown(false);
        }
      } else if (dropdownId === 'Jurídico') {
        if (dropDownClick) {
          setDropdown2(true);
        } else {
          setDropdown2(false);
        }
      } else if (dropdownId === 'Adequação do site') {
        if (dropDownClick) {
          setDropdown3(true);
        } else {
          setDropdown3(false);
        }
      }
    } else {
      if (dropdownId === 'Segurança da informação') {
        if (dropDownClick) {
          setDropdown(true);
        }
      } else if (dropdownId === 'Jurídico') {
        if (dropDownClick) {
          setDropdown2(true);
        }
      } else if (dropdownId === 'Adequação do site') {
        if (dropDownClick) {
          setDropdown3(true);
        }
      }
    }
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        Navigate('/');
      })
      .catch((error) => {});
  };

  return (
    <>
      {isMobile ? (
        <nav className='navbar'>
          <div className='nav-header'>
            <div to='/painel' className='navbar-logo' onClick={closeMobileMenu}>
              <img src={logo} alt='' />{' '}
            </div>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            {!dropdown && !dropdown2 && !dropdown3 && (
              <li className='nav-item'>
                <Link
                  to='/painel'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Home
                </Link>
              </li>
            )}
            {!dropdown2 && !dropdown3 && (
              <li
                className='nav-item'
                onClick={() => onClick('Segurança da informação')}
              >
                <div
                  // to="/services"
                  className='nav-links'
                  // onClick={closeMobileMenu}
                >
                  Segurança da informação{' '}
                  <i
                    className={
                      !dropDownClick
                        ? 'fas fa-caret-down active'
                        : 'fas fa-caret-down'
                    }
                  />
                </div>
                {dropdown && <Dropdown />}
              </li>
            )}

            {!dropdown && !dropdown3 && (
              <li className='nav-item' onClick={() => onClick('Jurídico')}>
                <div
                  // to="/services"
                  className='nav-links'
                  // onClick={closeMobileMenu}
                >
                  Jurídico{' '}
                  <i
                    className={
                      !dropDownClick
                        ? 'fas fa-caret-down active'
                        : 'fas fa-caret-down'
                    }
                  />
                </div>
                {dropdown2 && <Dropdown2 />}
              </li>
            )}
            {!dropdown && !dropdown2 && (
              <li
                className='nav-item'
                onClick={() => onClick('Adequação do site')}
              >
                <div
                  // to="/services"
                  className='nav-links'
                  // onClick={closeMobileMenu}
                >
                  Adequação do site{' '}
                  <i
                    className={
                      !dropDownClick
                        ? 'fas fa-caret-down active'
                        : 'fas fa-caret-down'
                    }
                  />
                </div>
                {dropdown3 && <Dropdown3 />}
              </li>
            )}

            {!dropdown && !dropdown2 && !dropdown3 && (
              <li className='nav-item'>
                <Link
                  to='/treinamentos'
                  className='nav-links'
                  // onClick={closeMobileMenu}
                >
                  Treinamentos
                </Link>
              </li>
            )}
            {!dropdown && !dropdown2 && !dropdown3 && (
              <li className='nav-item'>
                <Link
                  to='/treinamentos'
                  className='nav-links'
                  // onClick={closeMobileMenu}
                >
                  Checklist
                </Link>
                <Link to='/' className='nav-links' onClick={logout}>
                  Sair
                </Link>
              </li>
            )}
          </ul>
        </nav>
      ) : (
        <nav className='navbar'>
          <div to='/painel' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={logo} alt='' />{' '}
          </div>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link
                to='/painel'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Home
              </Link>
            </li>

            <li
              className='nav-item'
              onMouseEnter={() => {
                onMouseEnter('Segurança da informação');
              }}
              onMouseLeave={() => {
                onMouseLeave('Segurança da informação');
              }}
            >
              <div
                // to="/services"
                className='nav-links'
                // onClick={closeMobileMenu}
              >
                Segurança da informação <i className='fas fa-caret-down' />
              </div>
              {dropdown && <Dropdown />}
            </li>

            <li
              className='nav-item'
              onMouseEnter={() => {
                onMouseEnter('Jurídico');
              }}
              onMouseLeave={() => {
                onMouseLeave('Jurídico');
              }}
            >
              <div
                // to="/services"
                className='nav-links'
                // onClick={closeMobileMenu}
              >
                Jurídico <i className='fas fa-caret-down' />
              </div>
              {dropdown2 && <Dropdown2 />}
            </li>

            <li
              className='nav-item'
              onMouseEnter={() => {
                onMouseEnter('Adequação do site');
              }}
              onMouseLeave={() => {
                onMouseLeave('Adequação do site');
              }}
            >
              <div
                // to="/services"
                className='nav-links'
                // onClick={closeMobileMenu}
              >
                Adequação do site <i className='fas fa-caret-down' />
              </div>
              {dropdown3 && <Dropdown3 />}
            </li>

            <li className='nav-item'>
              <Link
                to='/treinamentos'
                className='nav-links'
                // onClick={closeMobileMenu}
              >
                Treinamentos
              </Link>
              <Link
                to='/checklist'
                className='nav-links'
                // onClick={closeMobileMenu}
              >
                Checklist
              </Link>
              <Link to='/' className='nav-links' onClick={logout}>
                Sair
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
}

export default Navbar;
