import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import Loading from "../Loading";
import CampoTexto from "../../CampoTexto";
const Container = styled.div`
  margin: 0 auto;
  max-width: 70%;
  form {
    display: flex;
    justify-content: center;
    border: 4px solid #be00ff;
    border-radius: 10px;
    padding: 25px;
  }
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const Titulo = styled.h1`
  text-align: center;
`;
const Identificacao = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Mensagem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    color: #be00ff;
    font-weight: 600;
  }

  textarea {
    /* border: 2px solid #be00ff; */
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    padding: 10px;
    /* color: #8d01bb; */
    font-size: 15px;
    &:focus {
      outline-color: #8d01bb;
    }
  }
  input {
    text-decoration: none;
    width: 50%;
    margin-top: 15px;
    text-align: center;
    padding: 25px 15px;
    background: rgb(91, 3, 144);
    background: linear-gradient(90deg, #840dce 0%, #f71bff 100%);
    /* -webkit-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1); */
    /* -moz-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1); */
    /* box-shadow: 0px 0px 65px -10px rgba(255, 76, 255, 1); */
    color: white;
    border-radius: 10px;
    border: none;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    font-weight: 600;
    transition: 0.5s;
    &:hover {
      cursor: pointer;
      scale: 1.03;
    }
  }
`;

const Formulario = () => {
  const [sucesso, setSucesso] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setSucesso(true);
          setIsLoading(false);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <div>
      <Navbar />
      <Container>
        <Title>Suporte</Title>
        <Loading isLoading={isLoading} />
        {!sucesso ? (
          <form ref={form} onSubmit={sendEmail}>
            <Identificacao>
              <div>
                <CampoTexto
                  required={true}
                  textColor={"#BE00FF"}
                  bold
                  marginRotulo={"10px"}
                  name="user_name"
                  label="Nome"
                  type="text"
                  // direction={"row"}
                ></CampoTexto>
                <CampoTexto
                  required={true}
                  label="Email"
                  type="email"
                  textColor={"#BE00FF"}
                  bold
                  marginRotulo={"10px"}
                  // direction={"row"}
                  max={"50%"}
                ></CampoTexto>
              </div>
            </Identificacao>
            <Mensagem>
              <label>Digite a sua dúvida</label>
              <textarea
                required={true}
                name="message"
                style={{ height: "200px", width: "400px" }}
              />
              <input type="submit" value="Enviar" />
            </Mensagem>
          </form>
        ) : (
          "Sucesso"
        )}
      </Container>
    </div>
  );
};

export default Formulario;
