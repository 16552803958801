import React, { useEffect, useState } from 'react';
import Navbar from '../NavbarTutorial/NavbarTutorial.js';
import CardDocumento from '../../../Componentes/CardDocumento/CardDocumento';
import CardDownloadAll from '../../../Componentes/CardDocumento/CardDownloadAll';
import './Painel2.css';
import { cardArray } from '../../../Pages/cardArray.js';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import NavbarTutorial from '../NavbarTutorial/NavbarTutorial.js';

const Tutorial = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState(
    'https://www.youtube.com/embed/jiM38P9RUjw?si=jxZkEH6K9jG_zvYx'
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const carregaVideo = (video) => {
    setUrl(video);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='pannel-main-container'>
      <NavbarTutorial func={carregaVideo} />
      {isMobile ? (
        <div className='pannel-container'>
          <div className='swiper-container'>
            <Swiper
              loop
              modules={[Navigation]}
              className='swiper'
              slidesPerView={'auto'}
              navigation={true}
              centeredSlides='true'
              spaceBetween={1}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {cardArray.map((item, index) => {
                return (
                  <SwiperSlide
                    onClick={() => (item.finished ? navigate(item.link) : '')}
                    key={item.id}
                    className='grid-item'
                  >
                    <CardDocumento
                      src={require(`../../../imgs/Imagens painel/${item.imgUrl}.png`)}
                      date={item.lastReview}
                      title={item.title}
                      // finished={item.finished}
                    ></CardDocumento>
                  </SwiperSlide>
                );
              })}
              <SwiperSlide className='grid-item'>
                <CardDownloadAll />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className='termometer-container'>
            <p className='termometer-title'>Tutorial</p>
            <p className='termometer-info2' style={{ textAlign: 'center' }}>
              Aqui você vai encontrar uma tela que imita a primeira tela do seu
              software. É só você ir até a tela que não conseguiu preencher e
              clicar. Você vai encontrar um vídeo com a própria idealizadora do
              software (Joana Faccini Salaverry) preenchendo a tela com você.
              Depois você volta aqui e diz se sua dúvida foi respondida.
            </p>
          </div>
        </div>
      ) : (
        <div className='pannel-container'>
          <div className={url ? 'card-grid-container2' : 'card-grid-container'}>
            {url ? (
              <iframe
                width='100%'
                height='504'
                src={url}
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowfullscreen
              ></iframe>
            ) : (
              <p>Imagem aqui</p>
            )}
            <p>
              Caso ainda possua alguma dúvida, você pode entrar em contato
              diretamente conosco!
            </p>
            <button
              className='tutorial-contact-button'
              onClick={() => navigate('/formulario')}
            >
              Contato
            </button>
          </div>
          <div className='termometer-container2'>
            <p className='termometer-title'>Tutorial</p>
            <p className='termometer-info2' style={{ textAlign: 'center' }}>
              Aqui você vai encontrar uma tela que imita a primeira tela do seu
              software. É só você ir até a tela que não conseguiu preencher e
              clicar. Você vai encontrar um vídeo com a própria idealizadora do
              software (Joana Faccini Salaverry) preenchendo a tela com você.
              Depois você volta aqui e diz se sua dúvida foi respondida.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tutorial;
