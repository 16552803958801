import React from 'react';

import './Options.css';
import { useNavigate } from 'react-router-dom';

const OpcoesDuvidaTecnica = (props) => {
  const navigate = useNavigate();
  return (
    <div className='options-container'>
      <button className='option-button' onClick={() => navigate('/faq')}>
        Perguntas Frequentes
      </button>
    </div>
  );
  // return <div className='options-container'>{props.texto}</div>;
};

export default OpcoesDuvidaTecnica;
