import React from "react";
import styled from "styled-components";

const Container = styled.div`
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    scale: 1.1;
  }
`;
const ButtonSvg = () => {
  return (
    <Container>
      <svg
        width="96"
        height="94"
        viewBox="0 0 96 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M85.6247 78.6182L45.7529 70.9053L62.9699 44.9135L85.6247 78.6182Z"
          fill="#150442"
        />
        <circle cx="40" cy="40" r="40" fill="url(#paint0_linear_373_213)" />
        <circle cx="21.5" cy="41.5" r="5.5" fill="white" />
        <circle cx="39.5" cy="41.5" r="5.5" fill="white" />
        <circle cx="57.5" cy="41.5" r="5.5" fill="white" />
        <defs>
          <linearGradient
            id="paint0_linear_373_213"
            x1="40"
            y1="0"
            x2="40"
            y2="80"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5B0390" />
            <stop offset="1" stop-color="#150442" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
};

export default ButtonSvg;
