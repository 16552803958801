import { createChatBotMessage } from "react-chatbot-kit";
import Options from "./Options";
import OpcoesPreencherTela from "./OpcoesPreencherTela";
import OpcoesLGPD from "./OpcoesLGPD";
import OpcoesDuvidaTecnica from "./OpcoesDuvidaTecnica";
import styled from "styled-components";
import SubtitleImg from "./chatbotimg.png";
import ChatImage from "./JoannaIcon.jpeg";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: rgb(21, 4, 66);
  background: linear-gradient(
    90deg,
    rgba(21, 4, 66, 1) 0%,
    rgba(91, 3, 144, 1) 100%
  );
  color: white;
  padding: 15px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  img {
    width: 80px;
    border-radius: 9999px;
    margin-right: 20px;
  }
`;

const AddedMainElementsContainer = styled.div`
  z-index: 99999999;
`;

const AddedElementsContainer = styled.div`
  background-color: white;
  width: 356px;

  height: 55px;
  display: flex;
  position: relative;

  z-index: 99999999;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  background-image: url(${SubtitleImg});
  /* background-position-y: -2px; */
  background-size: cover;
`;
const OnlineIndicator = styled.div`
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  min-width: 15px;
  background-color: #00de3e;
  box-shadow: 0px 0px 10px #00de3e, -0px -0px 10px #00de3e;
  border-radius: 6000px;
  z-index: 99999999;
  margin-left: 10px;
  margin-bottom: 5px;
`;

const SubtitleChatbotContainer = styled.div`
  min-height: 36px;
  max-height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 5px;
`;

const SubtitleChatbot = styled.h2`
  height: 100%;
  width: 100%;
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
`;

const config = {
  botName: "Joanna",
  initialMessages: [
    createChatBotMessage(`Olá, como podemos ajudá-lo?`, {
      widget: "options",
    }),
  ],
  customComponents: {
    header: () => (
      <AddedMainElementsContainer>
        <HeaderContainer>
          <img src={ChatImage} alt="" />
          <div>
            <p>Conversando com</p>
            <p>
              <strong>{config.botName}</strong>
            </p>
          </div>
        </HeaderContainer>
        <AddedElementsContainer>
          <OnlineIndicator></OnlineIndicator>
          <SubtitleChatbotContainer>
            <SubtitleChatbot>
              As dúvidas são respondidas em até 3 dias úteis
            </SubtitleChatbot>
          </SubtitleChatbotContainer>
        </AddedElementsContainer>
      </AddedMainElementsContainer>
    ),
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: "opcoesPreencherTela",
      widgetFunc: (props) => <OpcoesPreencherTela {...props} />,
    },
    {
      widgetName: "LGPD",
      widgetFunc: (props) => <OpcoesLGPD {...props} />,
    },
    {
      widgetName: "duvidaTecnica",
      widgetFunc: (props) => <OpcoesDuvidaTecnica {...props} />,
    },
  ],
};

export default config;
