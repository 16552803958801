import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../../imgs/idv images/AZUL ESCURO.png";
import SoLogo from "../../../imgs/idv images/AZUL so logo.png";
import Navbar from "../../Navbar/Navbar";

const FaqMainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  @media screen and (max-width: 760px) {
    margin-top: 0px;
    width: 100%;
    background: none;
  }
`;

const JustLogo = styled.img``;

const FaqContainer = styled.div`
  height: fit-content;
  @media screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const QuestionContainer = styled.div`
  width: 830px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 760px) {
    width: 90%;
    background: none;
  }
`;
const Question = styled.p`
  font-size: 2em;
  color: #560070;
  font-weight: 600;
  margin-top: 0;
  @media screen and (max-width: 760px) {
    font-size: 1em;
  }
`;
const Answer = styled.p`
  background-color: #560070;
  color: white;
  padding: 25px;
  height: auto;
  border-radius: 12px;
  position: relative;
  /* top: 80px; */
  margin: 0;
  @media screen and (max-width: 760px) {
    font-size: 0.8em;
  }
`;
const Triangle = styled.svg`
  display: none;
`;
const AnswerContainer = styled.div`
  animation: expand 0.5s ease-in-out forwards;
  height: 0px;
  overflow: hidden;
  /* position: absolute; */

  @keyframes expand {
    0% {
      height: 0px;
    }
    100% {
      height: ${window.innerWidth > 760 ? "150px" : "200px"};
    }
  }
  @keyframes dexpand {
    100% {
      height: 0px;
    }
    0% {
      height: ${window.innerWidth > 760 ? "150px" : "200px"};
    }
  }
`;

const FaqSectionContainer = styled.div`
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FaqHeaderSection = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 3px solid #560070;
  width: 50%;
  padding-bottom: 25px;
  justify-content: center;
  @media screen and (max-width: 760px) {
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
`;

const FaqHeader = styled.h2`
  font-size: 75px;
  margin: 0;
  color: #560070;
`;

const Faq = () => {
  const [firstQuestion, setFirstQuestion] = useState(false);
  const [secondQuestion, setSecondQuestion] = useState(false);
  const [thirdQuestion, setThirdQuestion] = useState(false);
  const [fourQuestion, setFourQuestion] = useState(false);
  const [fiveQuestion, setFiveQuestion] = useState(false);
  const [sixQuestion, setSixQuestion] = useState(false);

  function changeState(question) {
    if (question == 1) {
      setFirstQuestion(!firstQuestion);
    }
    if (question == 2) {
      setSecondQuestion(!secondQuestion);
    }
    if (question == 3) {
      setThirdQuestion(!thirdQuestion);
    }
    if (question == 4) {
      setFourQuestion(!fourQuestion);
    }
    if (question == 5) {
      setFiveQuestion(!fiveQuestion);
    }
    if (question == 6) {
      setSixQuestion(!sixQuestion);
    }
  }
  return (
    <div>
      <Navbar></Navbar>
      <FaqMainContainer>
        <FaqSectionContainer>
          <FaqHeaderSection>
            <JustLogo src={SoLogo}></JustLogo>
            <FaqHeader>F.A.Q</FaqHeader>
          </FaqHeaderSection>
          <FaqContainer>
            <QuestionContainer onClick={() => changeState(1)}>
              <Question>POR QUE EU PRECISO ME REGULARIZAR A LGPD? </Question>
              <Triangle></Triangle>
              {firstQuestion ? (
                <AnswerContainer
                  style={{ animation: "expand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Segundo a Lei Geral de Proteção de Dados (LGPD), todas as
                    empresas que lidam com dados pessoais e sensíveis precisam
                    garantir a segurança e privacidade dessas informações. O não
                    cumprimento das disposições da LGPD pode resultar em multas
                    significativas (de até 50 milhões de reais, dependendo do
                    faturamento bruto anual da empresa), danos à reputação da
                    empresa e de seus parceiros e processos judiciais.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Segundo a Lei Geral de Proteção de Dados (LGPD), todas as
                    empresas que lidam com dados pessoais e sensíveis precisam
                    garantir a segurança e privacidade dessas informações. O não
                    cumprimento das disposições da LGPD pode resultar em multas
                    significativas (de até 50 milhões de reais, dependendo do
                    faturamento bruto anual da empresa), danos à reputação da
                    empresa e de seus parceiros e processos judiciais.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(2)}>
              <Question>
                QUAIS OS BENEFÍCIOS DO LGPD LOCK PARA MINHA EMPRESA?{" "}
              </Question>
              <Triangle></Triangle>
              {secondQuestion ? (
                <AnswerContainer>
                  <Answer>
                    O LGPD LOCK traz mais segurança jurídica para a empresa,
                    permitindo uma gestão mais eficiente dos riscos relacionados
                    ao manuseio de dados. Além disso, promove um aumento no
                    padrão do serviço e da competitividade, tanto dentro quanto
                    fora do Brasil, ao demonstrar compromisso com a proteção da
                    privacidade dos clientes.
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    O LGPD LOCK traz mais segurança jurídica para a empresa,
                    permitindo uma gestão mais eficiente dos riscos relacionados
                    ao manuseio de dados. Além disso, promove um aumento no
                    padrão do serviço e da competitividade, tanto dentro quanto
                    fora do Brasil, ao demonstrar compromisso com a proteção da
                    privacidade dos clientes.
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(3)}>
              <Question>PARA QUEM É O LGPD LOCK? </Question>
              <Triangle></Triangle>
              {thirdQuestion ? (
                <AnswerContainer>
                  <Answer>
                    O LGPD LOCK é ideal para pequenas e médias empresas que
                    precisam se adequar à LGPD.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    O LGPD LOCK é ideal para pequenas e médias empresas que
                    precisam se adequar à LGPD.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(4)}>
              <Question>COMO O LGPD LOCK ME AJUDA? </Question>
              <Triangle></Triangle>
              {fourQuestion ? (
                <AnswerContainer>
                  <Answer>
                    O LGPD LOCK ajuda sua empresa a se adequar à LGPD de forma
                    eficiente e econômica. Com nossas soluções automatizadas,
                    você pode implementar políticas de privacidade, gerenciar
                    consentimentos, realizar auditorias de dados, e muito mais,
                    tudo em conformidade com as exigências da legislação.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    O LGPD LOCK ajuda sua empresa a se adequar à LGPD de forma
                    eficiente e econômica. Com nossas soluções automatizadas,
                    você pode implementar políticas de privacidade, gerenciar
                    consentimentos, realizar auditorias de dados, e muito mais,
                    tudo em conformidade com as exigências da legislação.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(5)}>
              <Question>
                VOU PRECISAR DE UMA EQUIPE ESPECIALIZADA EM LGPD?{" "}
              </Question>
              <Triangle></Triangle>
              {fiveQuestion ? (
                <AnswerContainer>
                  <Answer>
                    Não. O LGPD LOCK foi projetado para ser fácil de usar, mesmo
                    para empresas sem equipe dedicada de conformidade com a
                    LGPD. Nossa plataforma oferece recursos intuitivos e suporte
                    especializado para orientá-lo em cada etapa do processo de
                    adequação.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Não. O LGPD LOCK foi projetado para ser fácil de usar, mesmo
                    para empresas sem equipe dedicada de conformidade com a
                    LGPD. Nossa plataforma oferece recursos intuitivos e suporte
                    especializado para orientá-lo em cada etapa do processo de
                    adequação.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(6)}>
              <Question>
                É NECESSÁRIO TER CONHECIMENTO PRÉVIO SOBRE A LGPD?{" "}
              </Question>
              <Triangle></Triangle>
              {sixQuestion ? (
                <AnswerContainer>
                  <Answer>
                    Não. Nosso serviço foi desenvolvido para ser acessível a
                    todos, independentemente do nível de conhecimento prévio
                    sobre a LGPD. Além disso, disponibilizamos uma sequência de
                    treinamento para sanar suas dúvidas.
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Não. Nosso serviço foi desenvolvido para ser acessível a
                    todos, independentemente do nível de conhecimento prévio
                    sobre a LGPD. Além disso, disponibilizamos uma sequência de
                    treinamento para sanar suas dúvidas.
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
          </FaqContainer>
        </FaqSectionContainer>
      </FaqMainContainer>
    </div>
  );
};

export default Faq;
