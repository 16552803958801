import { useEffect, useRef, useState } from "react";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/firebase";
import { getAuth } from "firebase/auth";
import generatePDF from "react-to-pdf";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Navbar from "../../Componentes/Navbar/Navbar";
import ResultadoMatrizDeRisco from "../../Componentes/ResultadoMatrizDeRisco";
import ResultadoTabelaDeDescarte from "../../Componentes/ResultadoTabelaDeDescarte";

const Container = styled.div`
  /* max-width: 800px; */
  background-color: var(--branco);
  /* height: 100vh; */
  padding-bottom: 20px;
`;

const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 50px;
  align-items: center;
  width: 30%;
`;

const DocumentoTabelaDeDescarte = () => {
  const { state } = useLocation();
  const [usuario, setUsuario] = useState(getAuth()?.currentUser?.uid);
  const [dados, setDados] = useState(null);
  const targetRef = useRef();
  // let userId = getAuth()?.currentUser?.uid;

  const getDados = async () => {
    try {
      const docRef = doc(db, "usuarios", usuario);
      const docSnap = await getDoc(docRef);
      setDados(docSnap.data());
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data().MPR5);
        setDados(docSnap.data());
        console.log(dados);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDados();
  }, [usuario]);

  function teste() {
    console.log("tadeu");

    console.log(dados);
    return "teste";
  }

  return (
    <div>
      <Navbar />
      <div className="teste" ref={targetRef}>
        <Container>
          {dados && <ResultadoTabelaDeDescarte dados={dados} />}
        </Container>
      </div>
      <ButtonContainer>
        <ButtonPattern2
          onClick={() =>
            generatePDF(targetRef, {
              filename: "documentoLGPD.pdf,",
              method: "open",
              page: {
                margin: 20, // margin is in MM
                format: "A4",
                orientation: "portrait",
              },
            })
          }
        >
          Gerar PDF
        </ButtonPattern2>
      </ButtonContainer>
    </div>
  );
};

export default DocumentoTabelaDeDescarte;
