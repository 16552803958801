import React from "react";
import styled from "styled-components";
import whats from "./whatsbutton.png";
const WhatsContainer = styled.div`
  position: fixed;
  right: 2%;
  top: 90vh;
  transition: 0.5s;
  &:hover {
    scale: 1.08;
  }
  z-index: 100;
`;
const WhatsIco = styled.img`
  width: 70px;
  @media screen and (max-width: 760px) {
    width: 60px;
  }
`;
const WhatsButton = styled.a``;

const Whatsbutton = () => {
  return (
    <WhatsContainer>
      <WhatsButton
        target="blank"
        href="https://wa.me//555196846238?text=Ol%C3%A1!%20Venho%20atrav%C3%A9s%20do%20seu%20site%20e%20quero%20adequar%20minha%20empresa"
      >
        <WhatsIco src={whats}></WhatsIco>
      </WhatsButton>
    </WhatsContainer>
  );
};

export default Whatsbutton;
