export const MenuItems2 = [
  {
    title: "Relatório de impacto",
    path: "/relatorio-de-impacto",
    cName: "dropdown-link",
    video: "https://www.youtube.com/embed/b8-tXG8KrWs?si=sIWhHhGN5l_as5LM",
  },
  {
    title: "Política de segurança",
    path: "/politica-de-segurança",
    cName: "dropdown-link",
  },
  {
    title: "Aditivos - Funcionário CLT",
    path: "/aditivos-funcionario",
    cName: "dropdown-link",
  },
  {
    title: "Aditivos - Controlador",
    path: "/aditivos-controlador",
    cName: "dropdown-link",
  },
];
