import React, { useState } from 'react';
import { db } from '../../Firebase/firebase';
import { getAuth } from 'firebase/auth';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import CampoTexto from '../../Componentes/CampoTexto';
import CampoCheckBox from '../../Componentes/CampoCheckBox';
import { perguntasMapeamentoDeDados as perguntas } from '../../Dados/PerguntasMapeamentoDeDados';
import Navbar from '../../Componentes/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
  @media screen and (max-width: 960px) {
    margin: 2% 4%;
    padding: 3%;
  }
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;
const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const MapeamentoDeDados = () => {
  const navigate = useNavigate();
  // const [user, setUser] = useState(getAuth()?.currentUser?.email);
  const [nome, setNome] = useState();
  const [dataDePreenchimento, setdataDePreenchimento] = useState();
  const [pergunta2, setPergunta2] = useState('');
  const [pergunta3, setPergunta3] = useState('');
  const [pergunta4, setPergunta4] = useState('');
  const [pergunta58, setPergunta58] = useState('Não');
  const [pergunta59, setPergunta59] = useState('Não');
  const [pergunta60, setPergunta60] = useState('Não');
  const [pergunta61, setPergunta61] = useState('Não');
  const [pergunta62, setPergunta62] = useState('');
  const [pergunta91, setPergunta91] = useState('');
  const [pergunta92, setPergunta92] = useState('Não');
  const [pergunta93, setPergunta93] = useState('Não');
  const [pergunta94, setPergunta94] = useState('Não');
  const [pergunta95, setPergunta95] = useState('Não');
  const [pergunta96, setPergunta96] = useState('');
  const [pergunta97, setPergunta97] = useState('Não');
  const [pergunta98, setPergunta98] = useState('Não');
  const [pergunta99, setPergunta99] = useState('Não');
  const [pergunta100, setPergunta100] = useState('Não');
  const [pergunta101, setPergunta101] = useState('');
  const [pergunta102, setPergunta102] = useState('Não');
  const [pergunta103, setPergunta103] = useState('Não');
  const [pergunta104, setPergunta104] = useState('Não');
  const [pergunta105, setPergunta105] = useState('Não');
  const [pergunta106, setPergunta106] = useState('');
  const [pergunta107, setPergunta107] = useState('');
  const [mostraPergunta1, setMostraPergunta1] = useState(false);
  const [mostraPergunta2, setMostraPergunta2] = useState(false);
  const [mostraPergunta3, setMostraPergunta3] = useState(false);
  const [mostraPergunta4, setMostraPergunta4] = useState(false);
  const [arrayDeRespostas, setArrayDeRespostas] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const docRef = collection(db, 'usuarios');
  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: nome,
          [perguntas[1].codigo]: dataDePreenchimento,
          [perguntas[2].codigo]: pergunta2,
          [perguntas[3].codigo]: pergunta3,
          [perguntas[4].codigo]: pergunta4,
          [perguntas[5].codigo]: arrayDeRespostas[5] ? 'Sim' : 'Não',
          [perguntas[6].codigo]: arrayDeRespostas[6] ? 'Sim' : 'Não',
          [perguntas[7].codigo]: arrayDeRespostas[7] ? 'Sim' : 'Não',
          [perguntas[8].codigo]: arrayDeRespostas[8] ? 'Sim' : 'Não',
          [perguntas[9].codigo]: arrayDeRespostas[9] ? 'Sim' : 'Não',
          [perguntas[10].codigo]: arrayDeRespostas[10] ? 'Sim' : 'Não',
          [perguntas[11].codigo]: arrayDeRespostas[11] ? 'Sim' : 'Não',
          [perguntas[12].codigo]: arrayDeRespostas[12] ? 'Sim' : 'Não',
          [perguntas[13].codigo]: arrayDeRespostas[13] ? 'Sim' : 'Não',
          [perguntas[14].codigo]: arrayDeRespostas[14] ? 'Sim' : 'Não',
          [perguntas[15].codigo]: arrayDeRespostas[15] ? 'Sim' : 'Não',
          [perguntas[16].codigo]: arrayDeRespostas[16] ? 'Sim' : 'Não',
          [perguntas[17].codigo]: arrayDeRespostas[17] ? 'Sim' : 'Não',
          [perguntas[18].codigo]: arrayDeRespostas[18] ? 'Sim' : 'Não',
          [perguntas[19].codigo]: arrayDeRespostas[19] ? 'Sim' : 'Não',
          [perguntas[20].codigo]: arrayDeRespostas[20] ? 'Sim' : 'Não',
          [perguntas[21].codigo]: arrayDeRespostas[21] ? 'Sim' : 'Não',
          [perguntas[22].codigo]: arrayDeRespostas[22] ? 'Sim' : 'Não',
          [perguntas[23].codigo]: arrayDeRespostas[23] ? 'Sim' : 'Não',
          [perguntas[24].codigo]: arrayDeRespostas[24] ? 'Sim' : 'Não',
          [perguntas[25].codigo]: arrayDeRespostas[25] ? 'Sim' : 'Não',
          [perguntas[26].codigo]: arrayDeRespostas[26] ? 'Sim' : 'Não',
          [perguntas[27].codigo]: arrayDeRespostas[27] ? 'Sim' : 'Não',
          [perguntas[28].codigo]: arrayDeRespostas[28] ? 'Sim' : 'Não',
          [perguntas[29].codigo]: arrayDeRespostas[29] ? 'Sim' : 'Não',
          [perguntas[30].codigo]: arrayDeRespostas[30] ? 'Sim' : 'Não',
          [perguntas[31].codigo]: arrayDeRespostas[31] ? 'Sim' : 'Não',
          [perguntas[32].codigo]: arrayDeRespostas[32] ? 'Sim' : 'Não',
          [perguntas[33].codigo]: arrayDeRespostas[33] ? 'Sim' : 'Não',
          [perguntas[34].codigo]: arrayDeRespostas[34] ? 'Sim' : 'Não',
          [perguntas[35].codigo]: arrayDeRespostas[35] ? 'Sim' : 'Não',
          [perguntas[36].codigo]: arrayDeRespostas[36] ? 'Sim' : 'Não',
          [perguntas[37].codigo]: arrayDeRespostas[37] ? 'Sim' : 'Não',
          [perguntas[38].codigo]: arrayDeRespostas[38] ? 'Sim' : 'Não',
          [perguntas[39].codigo]: arrayDeRespostas[39] ? 'Sim' : 'Não',
          [perguntas[40].codigo]: arrayDeRespostas[40] ? 'Sim' : 'Não',
          [perguntas[41].codigo]: arrayDeRespostas[41] ? 'Sim' : 'Não',
          [perguntas[42].codigo]: arrayDeRespostas[42] ? 'Sim' : 'Não',
          [perguntas[43].codigo]: arrayDeRespostas[43] ? 'Sim' : 'Não',
          [perguntas[44].codigo]: arrayDeRespostas[44] ? 'Sim' : 'Não',
          [perguntas[45].codigo]: arrayDeRespostas[45] ? 'Sim' : 'Não',
          [perguntas[46].codigo]: arrayDeRespostas[46] ? 'Sim' : 'Não',
          [perguntas[47].codigo]: arrayDeRespostas[47] ? 'Sim' : 'Não',
          [perguntas[48].codigo]: arrayDeRespostas[48] ? 'Sim' : 'Não',
          [perguntas[49].codigo]: arrayDeRespostas[49] ? 'Sim' : 'Não',
          [perguntas[50].codigo]: arrayDeRespostas[50] ? 'Sim' : 'Não',
          [perguntas[51].codigo]: arrayDeRespostas[51] ? 'Sim' : 'Não',
          [perguntas[52].codigo]: arrayDeRespostas[52] ? 'Sim' : 'Não',
          [perguntas[53].codigo]: arrayDeRespostas[53] ? 'Sim' : 'Não',
          [perguntas[54].codigo]: arrayDeRespostas[54] ? 'Sim' : 'Não',
          [perguntas[55].codigo]: arrayDeRespostas[55] ? 'Sim' : 'Não',
          [perguntas[56].codigo]: arrayDeRespostas[56] ? 'Sim' : 'Não',
          [perguntas[57].codigo]: arrayDeRespostas[57] ? 'Sim' : 'Não',
          [perguntas[58].codigo]: pergunta58,
          [perguntas[59].codigo]: pergunta59,
          [perguntas[60].codigo]: pergunta60,
          [perguntas[61].codigo]: pergunta61,
          [perguntas[62].codigo]: pergunta62,
          [perguntas[63].codigo]: arrayDeRespostas[63] ? 'Sim' : 'Não',
          [perguntas[64].codigo]: arrayDeRespostas[64] ? 'Sim' : 'Não',
          [perguntas[65].codigo]: arrayDeRespostas[65] ? 'Sim' : 'Não',
          [perguntas[66].codigo]: arrayDeRespostas[66] ? 'Sim' : 'Não',
          [perguntas[67].codigo]: arrayDeRespostas[67] ? 'Sim' : 'Não',
          [perguntas[68].codigo]: arrayDeRespostas[68] ? 'Sim' : 'Não',
          [perguntas[69].codigo]: arrayDeRespostas[69] ? 'Sim' : 'Não',
          [perguntas[70].codigo]: arrayDeRespostas[70] ? 'Sim' : 'Não',
          [perguntas[71].codigo]: arrayDeRespostas[71] ? 'Sim' : 'Não',
          [perguntas[72].codigo]: arrayDeRespostas[72] ? 'Sim' : 'Não',
          [perguntas[73].codigo]: arrayDeRespostas[73] ? 'Sim' : 'Não',
          [perguntas[74].codigo]: arrayDeRespostas[74] ? 'Sim' : 'Não',
          [perguntas[75].codigo]: arrayDeRespostas[75] ? 'Sim' : 'Não',
          [perguntas[76].codigo]: arrayDeRespostas[76] ? 'Sim' : 'Não',
          [perguntas[77].codigo]: arrayDeRespostas[77] ? 'Sim' : 'Não',
          [perguntas[78].codigo]: arrayDeRespostas[78] ? 'Sim' : 'Não',
          [perguntas[79].codigo]: arrayDeRespostas[79] ? 'Sim' : 'Não',
          [perguntas[80].codigo]: arrayDeRespostas[80] ? 'Sim' : 'Não',
          [perguntas[81].codigo]: arrayDeRespostas[81] ? 'Sim' : 'Não',
          [perguntas[82].codigo]: arrayDeRespostas[82] ? 'Sim' : 'Não',
          [perguntas[83].codigo]: arrayDeRespostas[83] ? 'Sim' : 'Não',
          [perguntas[84].codigo]: arrayDeRespostas[84] ? 'Sim' : 'Não',
          [perguntas[85].codigo]: arrayDeRespostas[85] ? 'Sim' : 'Não',
          [perguntas[86].codigo]: arrayDeRespostas[86] ? 'Sim' : 'Não',
          [perguntas[87].codigo]: arrayDeRespostas[87] ? 'Sim' : 'Não',
          [perguntas[88].codigo]: arrayDeRespostas[88] ? 'Sim' : 'Não',
          [perguntas[89].codigo]: arrayDeRespostas[89] ? 'Sim' : 'Não',
          [perguntas[90].codigo]: arrayDeRespostas[90] ? 'Sim' : 'Não',
          [perguntas[91].codigo]: pergunta91,
          [perguntas[92].codigo]: pergunta92,
          [perguntas[93].codigo]: pergunta93,
          [perguntas[94].codigo]: pergunta94,
          [perguntas[95].codigo]: pergunta95,
          [perguntas[96].codigo]: pergunta96,
          [perguntas[97].codigo]: pergunta97,
          [perguntas[98].codigo]: pergunta98,
          [perguntas[99].codigo]: pergunta99,
          [perguntas[100].codigo]: pergunta100,
          [perguntas[101].codigo]: pergunta101,
          [perguntas[102].codigo]: pergunta102,
          [perguntas[103].codigo]: pergunta103,
          [perguntas[104].codigo]: pergunta104,
          [perguntas[105].codigo]: pergunta105,
          [perguntas[106].codigo]: pergunta106,
          [perguntas[107].codigo]: pergunta107,

          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      // navigate('/documento', { state: getAuth().currentUser.uid });
      navigate('/documento-mapeamento-dados');
    } catch (error) {
      console.log(error);
    }
  };

  const adicionaResposta = (posicao) => {
    const updatedCheckedState = arrayDeRespostas.map((item, index) =>
      index === posicao ? !item : item
    );

    setArrayDeRespostas(updatedCheckedState);
  };

  return (
    <>
      <Navbar />
      <div>
        <Title>Mapeamento De Dados</Title>
        <form onSubmit={cadastrar}>
          <NeumorphismContainer>
            <SectionTitle>Ciclo de Dados</SectionTitle>

            <CampoTexto
              required={true}
              label={perguntas[0].pergunta}
              placeholder={'Preencha seu nome completo'}
              onChange={(e) => setNome(e.target.value)}
            />

            <CampoTexto
              type={'date'}
              required={true}
              label={perguntas[1].pergunta}
              onChange={(e) => setdataDePreenchimento(e.target.value)}
            />

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <p>{perguntas[2].pergunta}</p>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao1'
                  label='Sim'
                  control={<Radio />}
                  onChange={() => setPergunta2('Sim')}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao2'
                  label='Não'
                  control={<Radio />}
                  onChange={() => setPergunta2('Não')}
                ></FormControlLabel>
              </div>
            </RadioGroup>

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <p>{perguntas[3].pergunta}</p>
              <div>
                <FormControlLabel
                  name='checkbox2'
                  type='radio'
                  value='opcao1'
                  label='Sim'
                  control={<Radio />}
                  onChange={() => setPergunta3('Sim')}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox2'
                  type='radio'
                  value='opcao2'
                  label='Não'
                  control={<Radio />}
                  onChange={() => setPergunta3('Não')}
                ></FormControlLabel>
              </div>
            </RadioGroup>

            <CampoTexto
              required={true}
              label={perguntas[4].pergunta}
              placeholder={
                'Adicione o nome das empresas separados por vírgula.'
              }
              onChange={(e) => setPergunta4(e.target.value)}
            />

            <CampoTexto
              required={true}
              label={perguntas[62].pergunta}
              onChange={(e) => setPergunta62(e.target.value)}
            />
          </NeumorphismContainer>
          <NeumorphismContainer>
            <SectionTitle>Agentes de Tratamento</SectionTitle>
            <p>
              Classifique se a sua empresa é uma operadora ou controladora de
              dados
            </p>
            <SectionTitle>Minha empresa com relação ao cliente:</SectionTitle>
            {perguntas.map((item, index) => {
              if ((index > 4 && index < 12) || (index > 62 && index < 70)) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={(e) => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}

            <SectionTitle>
              Minha empresa com relação ao prestador de serviço:
            </SectionTitle>
            {perguntas.map((item, index) => {
              if ((index > 11 && index < 19) || (index > 69 && index < 77)) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={(e) => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}

            <SectionTitle>
              Minha empresa com relação ao funcionário:
            </SectionTitle>
            {perguntas.map((item, index) => {
              if ((index > 18 && index < 26) || (index > 76 && index < 84)) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={() => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}

            <SectionTitle>
              Minha empresa com relação a outra empresa que é minha fornecedora:
            </SectionTitle>
            {perguntas.map((item, index) => {
              if ((index > 25 && index < 33) || (index > 83 && index < 91)) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={() => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}
          </NeumorphismContainer>
          <NeumorphismContainer>
            <div>
              <SectionTitle>Definição de Dados</SectionTitle>
              <p>Selecione os dados que são coletados pela sua empresa:</p>
              <SectionSubTitle>Dados de identificação pessoal:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 32 && index < 41) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}

              <SectionSubTitle>Dados financeiros:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 40 && index < 45) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}

              <SectionSubTitle>Hábitos pessoais:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 44 && index < 48) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}

              <SectionSubTitle>Dados sensíveis:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 47 && index < 58) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}
            </div>
          </NeumorphismContainer>
          <NeumorphismContainer>
            <SectionTitle>Base Legal</SectionTitle>
            <p>
              Vamos encontrar a base legal da coleta de dados na sua empresa,
              responda as perguntas abaixo:
            </p>
            <SectionTitle>Minha empresa com relação ao cliente</SectionTitle>
            <SectionSubTitle>
              Por qual motivo eu vou coletar os dados pessoais do cliente?
            </SectionSubTitle>

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao1'
                  label='Porque existe uma lei específica na minha área de atuação que exige que eu faça esta coleta dos dados pessoais dos clientes.'
                  control={<Radio />}
                  onChange={() => {
                    setPergunta58('Sim');
                    setPergunta59('Não');
                    setPergunta60('Não');
                    setPergunta61('Não');
                    setMostraPergunta1(true);
                  }}
                ></FormControlLabel>

                {mostraPergunta1 && (
                  <CampoTexto
                    required={true}
                    label={perguntas[91].pergunta}
                    onChange={(e) => setPergunta91(e.target.value)}
                  />
                )}
                <div>
                  <FormControlLabel
                    name='checkbox1'
                    type='radio'
                    value='opcao2'
                    label='Porque eu vou oferecer produtos depois para ele. Ou seja, vou realizar marketing.'
                    control={<Radio />}
                    onChange={() => {
                      setPergunta58('Não');
                      setPergunta59('Sim');
                      setPergunta60('Não');
                      setPergunta61('Não');
                      setMostraPergunta1(false);
                    }}
                  ></FormControlLabel>
                </div>
              </div>

              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao3'
                  label='Vocês vão celebrar um contrato entre vocês.'
                  control={<Radio />}
                  onChange={() => {
                    setPergunta58('Não');
                    setPergunta59('Não');
                    setPergunta60('Sim');
                    setPergunta61('Não');
                    setMostraPergunta1(false);
                  }}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao4'
                  label='Sou uma instituição financeira credora'
                  control={<Radio />}
                  onChange={() => {
                    setPergunta58('Não');
                    setPergunta59('Não');
                    setPergunta60('Não');
                    setPergunta61('Sim');
                    setMostraPergunta1(false);
                  }}
                ></FormControlLabel>
              </div>
            </RadioGroup>

            {/* ---------------- Minha empresa com relação ao fornecedor de serviço -------------- */}

            <SectionTitle>
              Minha empresa com relação ao fornecedor de serviço
            </SectionTitle>
            <SectionSubTitle>
              Por qual motivo eu vou coletar os dados pessoais do fornecedor?
            </SectionSubTitle>

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao1'
                  label={perguntas[92].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta92('Sim');
                    setPergunta93('Não');
                    setPergunta94('Não');
                    setPergunta95('Não');
                    setMostraPergunta2(true);
                  }}
                ></FormControlLabel>

                {mostraPergunta2 && (
                  <CampoTexto
                    required={true}
                    label={perguntas[96].pergunta}
                    onChange={(e) => setPergunta96(e.target.value)}
                  />
                )}
                <div>
                  <FormControlLabel
                    name='checkbox1'
                    type='radio'
                    value='opcao2'
                    label={perguntas[93].pergunta}
                    control={<Radio />}
                    onChange={() => {
                      setPergunta92('Não');
                      setPergunta93('Sim');
                      setPergunta94('Não');
                      setPergunta95('Não');
                      setMostraPergunta2(false);
                    }}
                  ></FormControlLabel>
                </div>
              </div>

              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao3'
                  label={perguntas[94].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta92('Não');
                    setPergunta93('Não');
                    setPergunta94('Sim');
                    setPergunta95('Não');
                    setMostraPergunta2(false);
                  }}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao4'
                  label={perguntas[95].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta92('Não');
                    setPergunta93('Não');
                    setPergunta94('Não');
                    setPergunta95('Sim');
                    setMostraPergunta2(false);
                  }}
                ></FormControlLabel>
              </div>
            </RadioGroup>

            {/*------------------ Empresa - Funcionario ------------------------ */}
            <SectionTitle>
              Minha empresa com relação a um funcionário
            </SectionTitle>
            <SectionSubTitle>
              Por qual motivo eu vou coletar os dados pessoais do funcionário?
            </SectionSubTitle>

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao1'
                  label={perguntas[97].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta97('Sim');
                    setPergunta98('Não');
                    setPergunta99('Não');
                    setPergunta100('Não');
                    setMostraPergunta3(true);
                  }}
                ></FormControlLabel>

                {mostraPergunta3 && (
                  <CampoTexto
                    required={true}
                    label={perguntas[101].pergunta}
                    onChange={(e) => setPergunta101(e.target.value)}
                  />
                )}
                <div>
                  <FormControlLabel
                    name='checkbox1'
                    type='radio'
                    value='opcao2'
                    label={perguntas[98].pergunta}
                    control={<Radio />}
                    onChange={() => {
                      setPergunta97('Não');
                      setPergunta98('Sim');
                      setPergunta99('Não');
                      setPergunta100('Não');
                      setMostraPergunta3(false);
                    }}
                  ></FormControlLabel>
                </div>
              </div>

              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao3'
                  label={perguntas[99].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta97('Não');
                    setPergunta98('Não');
                    setPergunta99('Sim');
                    setPergunta100('Não');
                    setMostraPergunta3(false);
                  }}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao4'
                  label={perguntas[100].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta97('Não');
                    setPergunta98('Não');
                    setPergunta99('Não');
                    setPergunta100('Sim');
                    setMostraPergunta3(false);
                  }}
                ></FormControlLabel>
              </div>
            </RadioGroup>

            {/*------------------ Outra Situação ------------------------ */}
            <SectionTitle>Outra situação</SectionTitle>
            <SectionSubTitle>
              Por qual motivo eu vou coletar dados pessoais nesta ocasião?
            </SectionSubTitle>

            <CampoTexto
              required={true}
              label={perguntas[107].pergunta}
              onChange={(e) => setPergunta107(e.target.value)}
            />

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao1'
                  label={perguntas[102].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta102('Sim');
                    setPergunta103('Não');
                    setPergunta104('Não');
                    setPergunta105('Não');
                    setMostraPergunta4(true);
                  }}
                ></FormControlLabel>

                {mostraPergunta4 && (
                  <CampoTexto
                    required={true}
                    label={perguntas[106].pergunta}
                    onChange={(e) => setPergunta106(e.target.value)}
                  />
                )}
                <div>
                  <FormControlLabel
                    name='checkbox1'
                    type='radio'
                    value='opcao2'
                    label={perguntas[103].pergunta}
                    control={<Radio />}
                    onChange={() => {
                      setPergunta102('Não');
                      setPergunta103('Sim');
                      setPergunta104('Não');
                      setPergunta105('Não');
                      setMostraPergunta4(false);
                    }}
                  ></FormControlLabel>
                </div>
              </div>

              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao3'
                  label={perguntas[104].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta102('Não');
                    setPergunta103('Não');
                    setPergunta104('Sim');
                    setPergunta105('Não');
                    setMostraPergunta4(false);
                  }}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao4'
                  label={perguntas[105].pergunta}
                  control={<Radio />}
                  onChange={() => {
                    setPergunta102('Não');
                    setPergunta103('Não');
                    setPergunta104('Não');
                    setPergunta105('Sim');
                    setMostraPergunta4(false);
                  }}
                ></FormControlLabel>
              </div>
            </RadioGroup>
          </NeumorphismContainer>

          <ButtonContainer>
            <ButtonPattern>Enviar</ButtonPattern>
            <ButtonPattern2
              onClick={() => navigate('/documento-mapeamento-dados')}
            >
              Visualizar Documento
            </ButtonPattern2>
          </ButtonContainer>
        </form>
      </div>
    </>
  );
};

export default MapeamentoDeDados;
