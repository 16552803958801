export const MenuItems3 = [
  {
    title: 'Política de privacidade',
    path: '/política-de-privacidade',
    cName: 'dropdown-link',
    video: 'https://www.youtube.com/embed/b8-tXG8KrWs?si=sIWhHhGN5l_as5LM',
  },
  {
    title: 'Barra de cookies',
    path: '/barra-de-cookies',
    cName: 'dropdown-link',
  },
];
