import React from 'react';

import { perguntasMapeamentoDeDados as perguntas } from '../Dados/PerguntasMapeamentoDeDados';
import styled from 'styled-components';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import sourceArial from '../Fonts/arial.ttf';
import sourceArialBold from '../Fonts/arialbd.ttf';

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 50px;
  text-align: center;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-top: 100px;
`;

const Texto = styled.p`
  font-size: 30px;
`;

const Li = styled.li`
  font-size: 30px;
`;

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Arial',
    width: '100%',
  },
  titulo: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '28px',
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    // fontWeight: '900',
  },
  section: {
    fontFamily: 'Arial',
    fontSize: 12,
    marginBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: 'justify',
  },
  p: {
    marginTop: 5,
    marginBottom: 5,
    textIndent: 30,
  },
  topico: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontWeight: '800',
    fontSize: 14,
  },
  lista: {
    fontWeight: '800',
  },
  recuo: {
    marginLeft: 30,
    fontWeight: '800',
  },
  recuoItens: {
    marginLeft: 50,
  },
});
const ResultadoTestePoliticaDeSeguranca = (props) => {
  console.log(props.dados);
  Font.register({
    family: 'Arial',
    fonts: [
      {
        src: sourceArial,
      },
      {
        src: sourceArialBold,
        fontWeight: 'bold',
      },
    ],
  });

  const MyDocument = () => (
    <Document style={{ width: '100%' }}>
      <Page size='A4' style={styles.page}>
        <View wrap={false}>
          <Text style={styles.titulo}>Política de Segurança da Informação</Text>
        </View>
        <View wrap={false} style={styles.section}>
          <Text style={styles.p}>
            A informação é um dos nossos principais ativos e deve ser usada de
            forma adequada e protegida contra riscos, ameaças, hacks, acesso não
            autorizado e danos. Consequentemente, é importante adotar ações,
            políticas e procedimentos padronizados para proteger as três
            principais áreas de segurança de dados: confidencialidade,
            integridade e disponibilidade.
          </Text>
          <Text style={styles.p}>
            Uma política de segurança da informação visa garantir que a
            segurança seja gerenciada dentro de uma organização estabelecendo
            regras e padrões para proteção de dados. As políticas permitem
            manter a confidencialidade, garantir que as informações não sejam
            alteradas ou perdidas e permitir que as informações estejam
            disponíveis quando necessários. A seguir você terá acesso a alguns
            assuntos. Sendo eles:
          </Text>

          {props.dados[0][0] && (
            <>
              <Text style={styles.topico}>
                Acesso Remoto de Funcionários/Colaboradores aos Dados da Empresa
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                Assine Já LGPD LOCK para a versão completa! Assine Já LGPD LOCK
                para a versão completa! Assine Já LGPD LOCK para a versão
                completa! Assine Já LGPD LOCK para a versão completa! Assine Já
                LGPD LOCK para a versão completa! Assine Já LGPD LOCK para a
                versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                Assine Já LGPD LOCK para a versão completa! Assine Já LGPD LOCK
                para a versão completa! Assine Já LGPD LOCK para a versão
                completa! Assine Já LGPD LOCK para a versão completa! Assine Já
                LGPD LOCK para a versão completa! Assine Já LGPD LOCK para a
                versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                Assine Já LGPD LOCK para a versão completa! Assine Já LGPD LOCK
                para a versão completa! Assine Já LGPD LOCK para a versão
                completa! Assine Já LGPD LOCK para a versão completa! Assine Já
                LGPD LOCK para a versão completa! Assine Já LGPD LOCK para a
                versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                Assine Já LGPD LOCK para a versão completa! Assine Já LGPD LOCK
                para a versão completa! Assine Já LGPD LOCK para a versão
                completa! Assine Já LGPD LOCK para a versão completa! Assine Já
                LGPD LOCK para a versão completa! Assine Já LGPD LOCK para a
                versão completa!
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {props.dados[0][1] && (
            <>
              <Text style={styles.topico}>
                Concessão e Acesso Remoto Para Terceiros
              </Text>
              <Text style={styles.p}>
                O acesso remoto aos recursos/serviços de informação e recursos
                de informática da empresa pode ser cedido a terceiros ou
                prestadores de serviços caso seja necessário para o seu
                trabalho.
              </Text>
              <Text style={styles.lista}>
                As seguintes regras devem ser observadas na cedência e
                utilização de acesso remoto a terceiros:
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {(props.dados[0][0] || props.dados[0][1]) && (
            <>
              <Text style={styles.topico}>Monitoramento do Acesso Remoto</Text>
              <Text style={styles.p}>
                Todas as informações acessadas, transmitidas, recebidas ou
                produzidas por meio de acesso remoto a recursos/serviços de
                informação ou recursos computacionais da empresa devem ser
                monitoradas sem expectativa de privacidade do usuário.
              </Text>
              <Text style={styles.p}>
                Enquanto a empresa controla o acesso remoto aos seus
                recursos/serviços de informação ou recursos de computação,
                reserva-se o direito de interceptar, gravar, armazenar, ler,
                copiar e divulgar por pessoas autorizadas ou divulgar para fins
                oficiais, incluindo investigação criminal, sem aviso ou aviso,
                qualquer informação transmitida proveniente de sua rede interna
                e destinada a redes externas ou não.
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {props.dados[0][2] && (
            <>
              <Text style={styles.topico}>
                Uso de Equipamentos Computacionais Pessoais no Ambiente
                Corporativo
              </Text>
              <Text style={styles.p}>
                A empresa disponibiliza aos seus colaboradores todos os recursos
                informáticos necessários à realização das suas tarefas.
              </Text>
              <Text style={styles.p}>
                A empresa pode, a seu critério, permitir o uso de computadores
                pessoais para executar tarefas de trabalho ou processar
                informações pertinentes a ele. O uso não autorizado de
                computadores pessoais em ambiente empresarial é considerado
                violação da política geral de segurança de dados e é tratado
                como violação de segurança de dados, em que o responsável está
                sujeito à sanções e penalidades previstas neste documento.
              </Text>
              <Text style={styles.p}>
                A empresa não se responsabiliza por fornecer suporte, atualizar,
                manter, substituir peças, licenciar software, indenizar ou
                cobrir custos relacionados ao uso de equipamentos pessoais.
              </Text>
              <Text style={styles.p}>
                O uso de equipamento de computador pessoal pela empresa para
                executar tarefas de trabalho ou armazenar arquivos não altera a
                propriedade da organização sobre os dados criados, armazenados,
                enviados, recebidos, modificados ou excluídos.
              </Text>
              <Text style={styles.p}>
                Todos os direitos de propriedade intelectual permanecem com a
                empresa. Se um usuário estiver autorizado a praticar o uso de
                computadores pessoais para executar tarefas de trabalho ou
                processar dados, os usuários são totalmente responsáveis pela
                segurança de seus dispositivos, garantindo que: o sistema
                operacional dos computadores pessoais esteja sempre atualizado e
                todos os patches/melhorias de segurança são feitos; computadores
                possuem ferramenta anti-malware e garantem atualizações em tempo
                real e verificações diárias de assinaturas de malware; O
                equipamento de computador usa apenas software licenciado com
                direitos autorais.
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {props.dados[0][3] && (
            <>
              <Text style={styles.topico}>
                Proteção Contra Códigos Maliciosos
              </Text>
              <Text style={styles.p}>
                A empresa fornece ferramentas para proteger seus
                recursos/serviços de informação e recursos de computação,
                incluindo estações de usuários, dispositivos móveis e servidores
                corporativos, contra ameaças e códigos maliciosos como vírus,
                trojans, worms, ferramentas de captura de tela e dados escritos,
                software de publicidade, etc.
              </Text>
              <Text style={styles.p}>
                Apenas uma ferramenta fornecida pela empresa pode ser usada para
                proteção contra códigos maliciosos.
              </Text>
              <Text style={styles.lista}>
                A ferramenta de proteção de código malicioso implementa as
                seguintes regras de uso:
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>

              <Text style={styles.topico}>
                Prevenir Usuários de Códigos Maliciosos ainda que existam
                ferramentas de proteção contra códigos maliciosos
              </Text>
              <Text style={styles.p}>
                Os usuários devem seguir condutas seguras que reduzam a
                probabilidade de infecção ou disseminação de códigos maliciosos.
                Para isso devem seguir as seguintes regras para se protegerem de
                códigos maliciosos:
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {props.dados[0][4] && (
            <>
              <Text style={styles.topico}>
                Uso de Serviço de E-mail Corporativo
              </Text>
              <Text style={styles.p}>
                A empresa fornece o serviço de e-mail para seus usuários
                autorizados exclusivamente para o desempenho de suas atividades
                profissionais. Não é permitido o uso de qualquer serviço de
                e-mail, que não seja o oficialmente fornecido pela empresa.
              </Text>
              <Text style={styles.lista}>
                Quando o usuário fizer uso do serviço de e-mail da empresa, não
                é permitido:
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa! Assine Já LGPD
                LOCK para a versão completa! Assine Já LGPD LOCK para a versão
                completa!
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {props.dados[0][5] && (
            <>
              <Text style={styles.topico}>Uso de Celulares Corporativos</Text>
              <Text style={styles.p}>
                A empresa fornece o serviço de celulares corporativos para seus
                usuários autorizados, exclusivamente para o desempenho de suas
                atividades profissionais.
              </Text>
              <Text style={styles.lista}>
                Quando o usuário fizer uso do serviço celulares corporativos da
                empresa, não é permitido:
              </Text>
              <Text style={styles.recuoItens}>
                • Utilizar do serviço de comunicadores instantâneos em caráter
                pessoal ou para fins que não sejam de interesse da empresa.
              </Text>
              <Text style={styles.recuoItens}>
                • Utilizar de termos ou palavras de baixo calão na redação de
                mensagens.
              </Text>
              <Text style={styles.recuoItens}>
                • Fazer uso de qualquer técnica forja ou simulação de falsa
                identidade. Qualquer tentativa, mesmo não consumada, será
                tratada como um incidente de segurança.
              </Text>
              <Text style={styles.recuoItens}>
                • A interceptação ou alteração do conteúdo da mensagem de outros
                usuários ou terceiros, a menos que devidamente autorizado.
              </Text>
              <Text style={styles.recuoItens}>
                • O usuário é o responsável exclusivo pelo uso inadequado de sua
                conta no serviço de comunicação instantânea, não sendo permitido
                o envio de mensagens cujo conteúdo incite uso de drogas,
                terrorismo, práticas subversivas, violência, aborto, práticas
                racistas, assim como qualquer outro que possa infringir a
                legislação vigente.
              </Text>
              <Text style={styles.recuoItens}>
                • O serviço é continuamente monitorado, não existindo qualquer
                tipo de expectativa de privacidade por parte dos usuários.
              </Text>
              <Text style={styles.recuoItens}>
                • O monitoramento tem como objetivos proteger a organização,
                atestar o respeito às regras contidas nessa norma, bem como
                produzir evidências relativas à eventual violação das mesmas
                e/ou à legislação em vigor.
              </Text>
              <Text style={styles.recuoItens}>
                • Durante o monitoramento a empresa se resguarda o direito de,
                sem qualquer notificação ou aviso, de monitorar, interceptar,
                registrar, ler, bloquear, redirecionar, retransmitir, copiar e
                divulgar por, ou para, pessoas autorizadas para finalidades
                oficiais, incluindo investigações criminais todas as mensagens
                enviadas ou recebidas pelos usuários através do presente
                serviço.
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {props.dados[0][6] && (
            <>
              <Text style={styles.topico}>Utilização da Internet</Text>
              <Text style={styles.p}>
                A empresa fornece acesso à Internet aos seus usuários
                autorizados, conforme as necessidades inerentes ao desempenho de
                suas atividades profissionais.
              </Text>
              <Text style={styles.p}>
                O acesso à internet pode ser fornecido tanto através da rede
                corporativa da empresa, quanto através da disponibilização de
                serviços de internet móvel, prestados por terceiros, contratados
                pela empresa.
              </Text>
              <Text style={styles.p}>
                Toda informação que é acessada, transmitida, recebida ou
                produzida através do acesso à internet fornecido pela empresa
                está sujeita monitoramento, não havendo por parte do usuário
                qualquer expectativa de privacidade.
              </Text>
              <Text style={styles.p}>
                Durante o monitoramento do acesso à internet, a empresa se
                resguarda o direito de, sem qualquer notificação ou aviso,
                interceptar, registrar, ler, copiar e divulgar por, ou para,
                pessoas autorizadas para finalidades oficiais, incluindo
                investigações criminais, toda informação trafegada, seja
                originada de sua rede interna e destinada a redes externas ou o
                contrário.
              </Text>
              <Text style={styles.p}>
                Durante o acesso à Internet fornecido pela empresa não será
                permitido o download, o upload, a inclusão, a disponibilização,
                a visualização, a edição, a instalação, o armazenamento e/ou a
                cópia de qualquer conteúdo relacionado expressa ou
                subjetivamente, direta ou indiretamente, com:
              </Text>

              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
              <Text style={[styles.p, { backgroundColor: 'black' }]}>
                • Assine Já LGPD LOCK para a versão completa!
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.p}>
            Para padronizar as informações organizacionais, esta política de
            segurança da informação e comunicação deve ser compartilhada com
            todos os gestores, colaboradores, terceiros e fornecedores da
            empresa para informação e conformidade.
          </Text>

          <Text style={styles.topico}>Aprovação:</Text>
          <Text style={{ textAlign: 'center' }}>
            {props.dados[1]} {` `}____________________________________
          </Text>
          <Text> </Text>
          <Text style={{ textAlign: 'center' }}>{props.dados[2]}</Text>
          <Text style={{ textAlign: 'center' }}>{props.dados[3]}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Container>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <MyDocument />
      </PDFViewer>
    </Container>
  );
};

export default ResultadoTestePoliticaDeSeguranca;
