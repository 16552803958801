import React, { useEffect, useState } from "react";
import Navbar from "../Componentes/Navbar/Navbar";
import CardDocumento from "../Componentes/CardDocumento/CardDocumento";
import CardDownloadAll from "../Componentes/CardDocumento/CardDownloadAll";
import imagem from "../imgs/idv images/teste.png";
import Termometro from "../Componentes/Termometro";
import "./Painel.css";
import { cardArray } from "./cardArray";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { doc, getDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Firebase/firebase";
import styled, { createGlobalStyle } from "styled-components";
import "swiper/css/navigation";
import "swiper/css/pagination";
import CampoTexto from "../Componentes/CampoTexto";

const MainContent = styled.div`
width: 27vw;
border-radius: 25px;
height: 65vh;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
const ModalAddUser = styled.div`
width: 100vw;
height: 100vh;
background-color: #54005f92;
position: absolute;
z-index: 99999;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
`
const CloseModal = styled.div`
position: absolute;
right: 37vw;
top: 18vh;
/* bottom: 120px; */
min-width: 30px;
min-height: 30px;
background-color: #be00fd;
background: linear-gradient(0deg, #3b0442 0%, rgba(190, 0, 255, 1) 100%);
border-radius: 50%;
color: white;
display: flex;
align-items: center;
justify-content: center;
font-size: 1rem;
cursor: pointer;
&:hover{
  scale: 1.08;
}
transition: .2s;
`



const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  &:hover{
  scale: 1.08;
}
transition: .2s;
`;

const ModalTitle = styled.h2`
color: #5b0390;
border-bottom: 2px solid #5b0390;
`

const ModalEmail = styled.h2``
const ModalSenha = styled.h2``
const ModalConfirmaSenha = styled.h2``
const BtnEnviar = styled.h2``
const ContentForm = styled.form`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
const AddUserButton = styled.div`
position: absolute;
right: 35px;
bottom: 120px;
width: 65px;
height: 65px;
background-color: #be00fd;
background: linear-gradient(0deg, #3b0442 0%, rgba(190, 0, 255, 1) 100%);
border-radius: 50%;
color: white;
display: flex;
align-items: center;
justify-content: center;
font-size: 3rem;
cursor: pointer;
&:hover{
  scale: 1.08;
}
transition: .2s;
`

const Painel = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [usuario, setUsuario] = useState(getAuth()?.currentUser?.uid);
  const [showModal, setShowModal] = useState(false);
  const [dados, setDados] = useState(null);
  const [finished, setFinished] = useState([]);

  const [email, setEmail] = useState(null);
  const [senha, setSenha] = useState(null);
  const [confirmaSenha, setConfirmaSenha] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [senhasErradas, setSenhasErradas] = useState(false);

  useEffect(() => {
    // Ouve as mudanças no estado de autenticação do usuário
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Usuário está logado
        setUserEmail(user.email);
      } else {
        // Usuário não está logado
        setUserEmail(null);
      }
    });

    // Limpa a inscrição quando o componente é desmontado
    return () => unsubscribe();
  }, []);
  
  const registerUser = async (email, password) => {
    try {
      // Cria um novo usuário com o e-mail e senha fornecidos
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // const user = userCredential.user;
      // console.log('Usuário criado com sucesso:', user);
      // return user; // Retorna o usuário criado
    } catch (error) {
      // Captura e exibe o erro caso ocorra
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error('Erro ao criar usuário:', errorCode, errorMessage);
      throw error; // Lança o erro para ser tratado pelo chamador
    }
  };

  const getDados = async () => {
    try {
      const docRef = doc(db, "usuarios", usuario);
      const docSnap = await getDoc(docRef);
      setDados(docSnap.data());
      if (docSnap.exists()) {
        setDados(docSnap.data());
        docSnap.data().MPR0
          ? (cardArray[0].finished = true)
          : (cardArray[0].finished = false);

        docSnap.data().MR0
          ? (cardArray[1].finished = true)
          : (cardArray[1].finished = false);
        docSnap.data().ADCLT0
          ? (cardArray[2].finished = true)
          : (cardArray[2].finished = false);
        docSnap.data().TD0
          ? (cardArray[3].finished = true)
          : (cardArray[3].finished = false);
        docSnap.data().RI0
          ? (cardArray[4].finished = true)
          : (cardArray[4].finished = false);
        docSnap.data().ADCTR0
          ? (cardArray[5].finished = true)
          : (cardArray[5].finished = false);
        docSnap.data().PSI0
          ? (cardArray[6].finished = true)
          : (cardArray[6].finished = false);
        docSnap.data().PPR0
          ? (cardArray[7].finished = true)
          : (cardArray[7].finished = false);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDados();
  }, [usuario]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="pannel-main-container">
      {userEmail == "usuario@mail.com" ||userEmail == "joana@mail.com" ? <AddUserButton onClick={()=>{setShowModal(true)}}>+</AddUserButton>: ""}
      {(userEmail == "usuario@mail.com" ||userEmail == "joana@mail.com") && showModal ? 
      <ModalAddUser>
        <CloseModal onClick={()=>setShowModal(false)}>X</CloseModal>
        <MainContent>
          <ModalTitle>Adicionar usuário</ModalTitle>
          <ContentForm onSubmit={(e)=>{
                   e.preventDefault();
                   if(senha == confirmaSenha){
                     registerUser(email, senha);
                   }else{
                    setSenhasErradas(true);
                    setTimeout(() => {
                      setSenhasErradas(false);
                    }, 3000);
                   }
          }}>
          <CampoTexto
          resetWidth
          customLabel={"E-mail"}
          required={true}
          placeholder={"Digite o e-mail"}
          type={"email"}
          onChange={(e) => setEmail(e.target.value)}
        />
                  <CampoTexto
                  resetWidth
          customLabel={"Senha"}
          required={true}
          placeholder={"Digite a senha"}
          type={"password"}
          onChange={(e) => setSenha(e.target.value)}
        />
                          <CampoTexto
                          resetWidth
          customLabel={"Confirmar Senha"}
          required={true}
          placeholder={"Confirme a senha"}
          type={"password"}
          onChange={(e) => setConfirmaSenha(e.target.value)}
        />
        {senhasErradas ? "As senhas não coincidem!" : ""}
        <ButtonPattern type="submit" onClick={(e)=>{
   

        }}>Enviar!</ButtonPattern>
      
            <ModalSenha></ModalSenha>
            <ModalConfirmaSenha></ModalConfirmaSenha>
            <BtnEnviar></BtnEnviar>
          </ContentForm>
        </MainContent>
      </ModalAddUser> : ""}
      <Navbar></Navbar>
      {isMobile ? (
        <div className="pannel-container">
          
          <div className="swiper-container">
            <Swiper
              loop
              modules={[Navigation]}
              className="swiper"
              slidesPerView={"auto"}
              navigation={true}
              centeredSlides="true"
              spaceBetween={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {cardArray.map((item, index) => {
                return (
                  <SwiperSlide
                    onClick={() => (item.finished ? navigate(item.link) : "")}
                    key={item.id}
                    className="grid-item"
                  >
                    <CardDocumento
                      src={require(`../imgs/Imagens painel/${item.imgUrl}.png`)}
                      date={item.lastReview}
                      title={item.title}
                      // finished={item.finished}
                    ></CardDocumento>
                  </SwiperSlide>
                );
              })}
              <SwiperSlide className="grid-item">
                <CardDownloadAll />
              </SwiperSlide>
            </Swiper>{" "}
          </div>
          <div className="termometer-container">

            <p className="termometer-title">Termômetro</p>
            <p className="termometer-info" style={{ textAlign: "center" }}>
              O objetivo do termômetro da adequação é mostrar para você o
              caminho da adequação completa da sua empresa. Assim que você
              percorrer todos os pontos (e preencher todos os documentos) da
              Segurança da Informação, da parte jurídica, da adequação do site e
              dos treinamentos seu termômetro marcará os 100%. Lembrando que a
              jornada de adequação não termina por aqui. Você precisa manter os
              documentos atualizados e repassar os treinamentos para seus
              funcionários ao longo do tempo.
            </p>
            <Termometro></Termometro>
          </div>
        </div>
      ) : (
        <div className="pannel-container">
          <div className="card-grid-container">
            {cardArray.map((item, index) => {
              return (
                <div
                  onClick={() => (item.finished ? navigate(item.link) : "")}
                  key={item.id}
                  className="grid-item"
                >
                  <CardDocumento
                    src={
                      item.finished
                        ? require(`../imgs/Imagens painel/${item.imgUrl}.png`)
                        : require(`../imgs/fechadura.png`)
                    }
                    date={item.lastReview}
                    title={item.title}
                    finished={item.finished}
                  ></CardDocumento>
                </div>
              );
            })}

            <div className="grid-item">
              <CardDownloadAll />
            </div>
          </div>
          <div className="termometer-container">
            <p className="termometer-title">Termômetro</p>
            <p className="termometer-info" style={{ textAlign: "center" }}>
              O objetivo do termômetro da adequação é mostrar para você o
              caminho da adequação completa da sua empresa. Assim que você
              percorrer todos os pontos (e preencher todos os documentos) da
              Segurança da Informação, da parte jurídica, da adequação do site e
              dos treinamentos seu termômetro marcará os 100%. Lembrando que a
              jornada de adequação não termina por aqui. Você precisa manter os
              documentos atualizados e repassar os treinamentos para seus
              funcionários ao longo do tempo.
            </p>
            <Termometro></Termometro>
          </div>
        </div>
      )}

      {/* <CardDownloadAll></CardDownloadAll> */}
      {/* <Termometro></Termometro> */}
    </div>
  );
};

export default Painel;
