import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  /* align-items: center; */
  min-width: 400px;
  border: 0.5px solid var(--borda-texto);
  padding: 9px 5px;
  margin: 10px;
  border-radius: 5px;
  /* @media (max-width: 600px) {
    width: 100vw;
    flex-wrap: wrap;
    margin: 15px 10px;
    font-size: 18px;
    min-width: 100px;
  } */
  @media screen and (max-width: 960px) {
    min-width: auto;
    width: 100%;
    margin: 10px 0;
  }
`;

const Rotulo = styled.label`
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  color: var(--azul-escuro-titulo);
`;

const Input = styled.input`
  /* width: 100%; */
  border-color: var(--cinza);
  border: 0.5px;
  @media screen and (max-width: 960px) {
    margin-right: 10px;
  }
`;

const CampoCheckBox = (props) => {
  return (
    <Container>
      <Input
        checked={props.checked}
        type="checkbox"
        id={props.id}
        name={props.name}
        onChange={(e) => props.onChange(e)}
        value={props.value}
        disabled={props.disabled}
      />
      <Rotulo htmlFor={props.id}>{props.label}</Rotulo>
    </Container>
  );
};

export default CampoCheckBox;
