import React, { Suspense, useState } from "react";
import "./LandingNew.css";
import styled from "styled-components";
import Logo from "../../imgs/idv images/AZUL ESCURO.png";
import SoLogo from "../../imgs/idv images/AZUL so logo.png";
import LogoNavbarImg from "../../imgs/idv images/BRANCOstext.png";
import LogoNavbarTextoImg from "../../imgs/idv images/so texto.png";
import Video1 from "../../Mockups/Mockup1.mp4";
import Video2 from "../../Mockups/Mockup2.mp4";
import BgImgVideo from "./Bgimg.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import ImgCellphone from "./mockpimg.png";
import CellphoneBg1 from "./bg1.gif";
import CellphoneBg2 from "./bg2.gif";
import CellphoneBg3 from "./bg3.gif";
import CellphoneBg4 from "./bg4.gif";
import CellphoneBg5 from "./bg5.gif";
import CellphoneBg6 from "./bg6.gif";
import { useNavigate } from "react-router-dom";
import Whatsbutton from "../../Componentes/Whatsbutton";
import VideoMac from "./bettermac.mp4";
import VideoMacMobile from "./bettermacmobile.mp4";

const MockupBgs = [
  CellphoneBg1,
  CellphoneBg2,
  CellphoneBg3,
  CellphoneBg4,
  CellphoneBg5,
  CellphoneBg6,
];

const Spline = React.lazy(() => import("@splinetool/react-spline"));

const MainContainer = styled.div``;

const Container = styled.div`
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 760px) {
    flex-direction: column;
    justify-content: center;
  }

  canvas {
    width: 50%;
    @media screen and (max-width: 760px) {
      width: 10%;
    }
  }
  .suspense-div {
    @media screen and (max-width: 760px) {
      width: 80%;
      height: 50%;
    }
  }
`;

const MacVideo = styled.video`
  /* @media screen and (max-width: 760px) { */
`;
const MacVideoMobile = styled.video`
  @media screen and (max-width: 760px) {
    width: 100%;
    max-height: 96%;
  }
`;

const FirstSectionHeader = styled.h1`
  font-size: 3em;
  font-family: "Big Shoulders Text", sans-serif;
  font-weight: 400;
  color: #150442;
  width: 70%;
  width: 100%;
  @media screen and (max-width: 760px) {
    font-size: 2em;
    text-align: center;
  }
`;
const FirstSectionTextContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 760px) {
    width: 90%;
    /* height: 80%; */
    align-items: center;
  }
`;

const ContainerSecondSection = styled.div`
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  &::-webkit-crollbar {
    display: none;
  }
  /* justify-content: center; */
  @media screen and (max-width: 760px) {
    flex-direction: column;
    overflow: hidden;
    height: 80vh;
  }
`;

const SecondSectionTextContainer = styled.div`
  min-width: 600px;
  position: relative;
  left: -400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 760px) {
    position: static;
    min-width: 90vw;
    max-width: 90vw;
    /* padding: 10%; */
  }
`;
const SecondSectionText = styled.p`
  font-size: 2em;
  font-family: "Big Shoulders Text", sans-serif;
  color: #150442;
  text-align: justify;
  @media screen and (max-width: 760px) {
    font-size: 1.2em;
    text-align: center;
    width: 100%;
  }
`;

const ContainerThirdSection = styled.div`
  /* height: 100; */
  background-color: #ffffff;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  /* justify-content: center; */
`;

const ThirdSectionTextContainer = styled.div`
  min-width: 350px;
  position: relative;
  /* margin-left: 20%;
  margin-right: 20%; */
  /* top: 236vh; */
  left: -100vw;
  min-width: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;

  div {
    display: flex;
    align-items: center;

    flex-direction: column;

    justify-content: center;
    max-width: 70%;
    padding: 25px;
    background: rgba(55, 0, 71, 0.829);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.7px);
    -webkit-backdrop-filter: blur(5.7px);
  }
`;
const ThirdSectionText = styled.p`
  font-size: 2em;
  font-family: "Big Shoulders Text", sans-serif;
  color: white;
  text-align: center;
  @media screen and (max-width: 760px) {
    font-size: 1.2em;
  }
`;

const CtaButton = styled.a`
  width: ${(props) => (props.size ? props.size : "85%")};
  text-decoration: none;

  text-align: center;
  padding: 25px 15px;
  background: rgb(91, 3, 144);
  background: linear-gradient(90deg, #840dce 0%, #f71bff 100%);
  -webkit-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1);
  -moz-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1);
  box-shadow: 0px 0px 65px -10px rgba(255, 76, 255, 1);
  color: white;
  border-radius: 10px;
  border: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: 600;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    scale: 1.03;
  }
`;

const CtaFirstButton = styled.a`
  width: ${(props) => (props.size ? props.size : "85%")};
  text-align: center;
  padding: 20px 10px;
  text-decoration: none;
  /* background: rgb(91, 3, 144); */
  background: linear-gradient(90deg, #840dce 0%, #f71bff 100%);
  /* -webkit-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1);
  -moz-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1);
  box-shadow: 0px 0px 40px -10px #7c007c; */
  color: white;
  border-radius: 10px;
  border: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: 600;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    scale: 1.03;
  }
`;

const FirstSectionLogo = styled.img`
  width: 45%;
  margin-bottom: 20px;
`;
const NavBar = styled.div`
  width: 100%;
  height: 80px;
  background-color: #560070;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 100;
`;
const LogoNavbarContainer = styled.div`
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoNavbar = styled.img`
  height: 80%;
  @media screen and (max-width: 760px) {
    width: 100%;
    height: auto;
  }
`;
const LogoNavbarTexto = styled.img`
  height: 60%;
  @media screen and (max-width: 760px) {
    width: 30%;
    height: auto;
    margin-left: 10px;
  }
`;
const TextoNavbar = styled.p`
  color: white;
  text-align: center;
  font-size: 35px;
  font-family: "Big Shoulders Text", sans-serif;
  margin-left: 1%;
  @media screen and (max-width: 760px) {
    font-size: 20px;
  }
`;

const TextNavbarContainer = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoSecondSection = styled.video`
  width: 80%;
  @media screen and (max-width: 760px) {
    width: 120%;
    overflow: hidden;
    padding-left: 22%;
  }
`;
const VideoThirdSection = styled.video`
  min-width: 100%;
  /* min-height: 100%; */
`;

const FaqSectionContainer = styled.div`
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FaqHeaderSection = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 3px solid #560070;
  width: 50%;
  padding-bottom: 25px;
  justify-content: center;
  @media screen and (max-width: 760px) {
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
`;
const JustLogo = styled.img``;
const FaqHeader = styled.h2`
  font-size: 75px;
  margin: 0;
  color: #560070;
`;
const FaqContainer = styled.div`
  height: fit-content;
  @media screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const QuestionContainer = styled.div`
  width: 830px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 760px) {
    width: 90%;
    background: none;
  }
`;
const Question = styled.p`
  font-size: 2em;
  color: #560070;
  font-weight: 600;
  margin-top: 0;
  @media screen and (max-width: 760px) {
    font-size: 1em;
  }
`;
const Answer = styled.p`
  background-color: #560070;
  color: white;
  padding: 25px;
  height: auto;
  border-radius: 12px;
  position: relative;
  /* top: 80px; */
  margin: 0;
  @media screen and (max-width: 760px) {
    font-size: 0.8em;
  }
`;
const Triangle = styled.svg`
  display: none;
`;
const AnswerContainer = styled.div`
  animation: expand 0.5s ease-in-out forwards;
  height: 0px;
  overflow: hidden;
  /* position: absolute; */

  @keyframes expand {
    0% {
      height: 0px;
    }
    100% {
      height: ${window.innerWidth > 760 ? "150px" : "200px"};
    }
  }
  @keyframes dexpand {
    100% {
      height: 0px;
    }
    0% {
      height: ${window.innerWidth > 760 ? "150px" : "200px"};
    }
  }
`;

const FaqMainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  @media screen and (max-width: 760px) {
    margin-top: 0px;
    width: 100%;
    background: none;
  }
`;
const VideoSectionContainer = styled.div`
  height: 100vh;
  overflow: hidden;
`;

const HeaderVideoSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HeaderVideoSection = styled.h2`
  font-size: 2em;
  color: #560070;
  font-weight: 600;
  text-align: center;
  margin: 0;
  width: 35%;
  @media screen and (max-width: 760px) {
    width: 90%;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  margin: 4% 0;
`;

const VideoSectionVideo = styled.iframe``;

const FloatingLogo = styled.img`
  position: relative;
  bottom: -10%;
  right: -${(props) => props.right};
  left: ${(props) => props.left};
  width: ${(props) => props.size};
  animation: floating 25s infinite linear;
  animation-delay: ${(props) => props.delay};
  z-index: -1;
  transform: rotate(${(props) => props.rotation});

  @keyframes floating {
    100% {
      transform: translateY(-130vh);
    }
  }
`;

const VideoSectionContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  @media screen and (max-width: 760px) {
    justify-content: start;
  }
`;

const VideoVideoVideo = styled.div`
  /* left:0; */
  width: 50%;
  background: url(${BgImgVideo});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  @media screen and (max-width: 760px) {
    width: 90%;
    background: none;
  }
`;

const FunctionSection = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 10%;

  .swiper-slide {
    flex-direction: column;
    align-items: start;
    @media screen and (max-width: 760px) {
      align-items: center;
      justify-content: start;
      margin-bottom: 30px;
    }
  }
  .swiper-pagination-bullet {
    background-color: #f71bff;
  }
  @media screen and (max-width: 760px) {
    flex-direction: column-reverse;
    gap: 0%;
  }
`;

const CellphoneMockup = styled.div`
  background: url(${ImgCellphone});
  height: 80%;
  width: 20%;
  background-repeat: no-repeat;
  background-size: contain;
  @media screen and (max-width: 760px) {
    width: 50%;
    height: 65%;
  }
`;

const CellphoneContent = styled.img`
  width: 100%;
  height: 100%;
`;

const FunctionTextContainer = styled.div`
  width: 40%;
  @media screen and (max-width: 760px) {
    width: 95%;
    font-size: 0.9em;
    text-align: center;
  }
`;

const FunctionText = styled.p`
  font-size: 3em;
  font-family: "Big Shoulders Text", sans-serif;
  color: #150442;
  text-align: justify;
  @media screen and (max-width: 760px) {
    font-size: 1.2em;
    text-align: center;
    /* margin: 10px 0; */
  }
`;

const FunctionHeader = styled.h2`
  font-size: 3em;
  font-family: "Big Shoulders Text", sans-serif;
  color: #f71bff;
  @media screen and (max-width: 760px) {
    font-size: 1.7em;
    text-align: center;
    margin: 0px 0;
  }
`;

const MacVideoContainer = styled.div`
  @media screen and (max-width: 760px) {
    width: 100%;
    height: 50%;
  }
`;

const LandingNew = () => {
  const navigate = useNavigate();
  const [firstQuestion, setFirstQuestion] = useState(false);
  const [secondQuestion, setSecondQuestion] = useState(false);
  const [thirdQuestion, setThirdQuestion] = useState(false);
  const [fourQuestion, setFourQuestion] = useState(false);
  const [fiveQuestion, setFiveQuestion] = useState(false);
  const [sixQuestion, setSixQuestion] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [swiperInstance, setSwiperInstance] = useState();
  const [bg, setBg] = useState(MockupBgs[0]);

  function changeState(question) {
    if (question == 1) {
      setFirstQuestion(!firstQuestion);
    }
    if (question == 2) {
      setSecondQuestion(!secondQuestion);
    }
    if (question == 3) {
      setThirdQuestion(!thirdQuestion);
    }
    if (question == 4) {
      setFourQuestion(!fourQuestion);
    }
    if (question == 5) {
      setFiveQuestion(!fiveQuestion);
    }
    if (question == 6) {
      setSixQuestion(!sixQuestion);
    }
  }

  function handleSlideChange() {
    if (currentSlide < 5) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
    console.log(currentSlide);
  }
  return (
    <MainContainer>
      <Whatsbutton />
      <Container
        style={{
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          height: "100vh",
        }}
        className="LandingNew"
      >
        <NavBar>
          <LogoNavbarContainer>
            <LogoNavbar src={LogoNavbarImg}></LogoNavbar>
          </LogoNavbarContainer>
          <TextNavbarContainer>
            <LogoNavbarTexto src={LogoNavbarTextoImg}></LogoNavbarTexto>
            <TextoNavbar>O software mais acessível do mercado</TextoNavbar>
          </TextNavbarContainer>
        </NavBar>
        <Suspense className="suspense-div" fallback="Carregando...">
          {/* <Spline
            style={
              window.innerWidth <= 768
                ? { width: "100%", height: "50%" }
                : { width: "50%" }
            }
            scene="https://prod.spline.design/6t1-97sz4eS0OrZu/scene.splinecode"
          /> */}
          <MacVideoContainer>
            {window.innerWidth > 760 ? (
              <MacVideo loop autoPlay muted src={VideoMac}></MacVideo>
            ) : (
              <MacVideoMobile
                loop
                autoPlay
                muted
                src={VideoMacMobile}
              ></MacVideoMobile>
            )}
          </MacVideoContainer>
        </Suspense>
        <FirstSectionTextContainer>
          <FirstSectionHeader>
            {" "}
            Sua empresa adequada à LGPD em poucos cliques, sem que você precise
            entender da Lei.
          </FirstSectionHeader>
          <FirstSectionLogo src={Logo}></FirstSectionLogo>
          <CtaFirstButton
            target="blank"
            href="https://wa.me//555196846238?text=Ol%C3%A1!%20Venho%20atrav%C3%A9s%20do%20seu%20site%20e%20quero%20adequar%20minha%20empresa"
            fontSize="18px"
            size="50%"
            style={{ backgroundColor: "#d341ff;" }}
          >
            [EU QUERO ADEQUAR MINHA EMPRESA]
          </CtaFirstButton>
        </FirstSectionTextContainer>
      </Container>
      <ContainerSecondSection>
        <VideoSecondSection src={Video1} autoPlay muted />
        <SecondSectionTextContainer>
          <SecondSectionText>
            Todas as empresas que lidam com dados pessoais e sensíveis no Brasil
            devem se adequar à LGPD, inclusive aquelas que solicitam apenas
            informações básicas dos clientes, como CPF, nome e endereço. Para
            pequenas e médias empresas, o desafio de se adequar pode ser maior
            devido aos recursos limitados. <br />
            <br /> O LGPD LOCK oferece uma solução automatizada e econômica,
            permitindo que as PMEs atendam aos requisitos da LGPD e economizem
            até 85% em comparação com outras soluções disponíveis.
          </SecondSectionText>
          <CtaButton
            target="blank"
            href="https://wa.me//555196846238?text=Ol%C3%A1!%20Venho%20atrav%C3%A9s%20do%20seu%20site%20e%20quero%20adequar%20minha%20empresa"
          >
            [QUERO FALAR COM ESPECIALISTAS E ADEQUAR MINHA EMPRESA]
          </CtaButton>
        </SecondSectionTextContainer>
      </ContainerSecondSection>
      <ContainerThirdSection>
        <VideoThirdSection loop src={Video2} autoPlay muted />
        <ThirdSectionTextContainer>
          <div>
            <ThirdSectionText>
              Nosso software é projetado com uma interface intuitiva e
              orientada, guiando os usuários por meio de etapas simples para
              garantir a proteção de dados de acordo com a LGPD. Para utilizar
              nossas ferramentas, não é necessário conhecimento técnico ou
              equipe especializada.{" "}
            </ThirdSectionText>
            <CtaButton
              fontSize={window.innerWidth > 760 ? "20px" : "15px"}
              size={window.innerWidth > 760 ? "30%" : "90%"}
              style={{ backgroundColor: "#d341ff;" }}
              onClick={() => {
                navigate("/testar");
              }}
            >
              [TESTAR A AUTOMAÇÃO COM MAIOR AGILIDADE DO MERCADO]
            </CtaButton>
          </div>
        </ThirdSectionTextContainer>
      </ContainerThirdSection>

      <FunctionSection>
        <FunctionTextContainer>
          <Swiper
            onSwiper={(swiper) => {
              setSwiperInstance(swiper);
            }}
            onSlideChange={() => setBg(MockupBgs[swiperInstance.realIndex])}
            slidesPerView={1}
            spaceBetween={100}
            pagination={true}
            autoplay={{
              delay: 5000,
            }}
            modules={[Autoplay, Pagination]}
          >
            <SwiperSlide>
              <FunctionHeader>Muito fácil de usar</FunctionHeader>
              <FunctionText>
                Nosso software é projetado com uma interface intuitiva e
                orientada. Para utilizar nossas ferramentas não é necessário
                conhecimento técnico ou equipe especializada.{" "}
              </FunctionText>
            </SwiperSlide>
            <SwiperSlide>
              <FunctionHeader>Termômetro de adequação </FunctionHeader>
              <FunctionText>
                Durante todo o processo, um medidor de progresso em tempo real
                mostra o quanto você progrediu em direção aos 100% de adequação.
                Quando alcançar a conformidade, nosso recurso "LGPD Lock" irá
                lembrá-lo de manter os documentos atualizados regularmente.
              </FunctionText>
            </SwiperSlide>
            <SwiperSlide>
              <FunctionHeader>Treinamentos curtos e didáticos </FunctionHeader>
              <FunctionText>
                Oferecemos treinamentos para sua equipe, garantindo que todos os
                colaboradores sejam capacitados para lidar com questões
                relacionadas à LGPD de maneira eficaz.
              </FunctionText>
            </SwiperSlide>
            <SwiperSlide>
              <FunctionHeader>
                Gestão simplificada de dados pessoais{" "}
              </FunctionHeader>
              <FunctionText>
                Permitimos que as empresas coletem, armazenem e processem
                informações de acordo com a lei, sem a necessidade de lidar com
                procedimentos complicados ou custos elevados com especialistas
                em LGPD.
              </FunctionText>
            </SwiperSlide>
            <SwiperSlide>
              <FunctionHeader>Preços acessíveis</FunctionHeader>
              <FunctionText>
                Tornamos a conformidade possível para empresas de todos os
                tamanhos. Não importa o seu orçamento, nossa solução está ao seu
                alcance.
              </FunctionText>
            </SwiperSlide>
            <SwiperSlide>
              <FunctionHeader>
                Geradores automáticos de documentos
              </FunctionHeader>
              <FunctionText>
                Nosso sistema foi criado para ajudar sua empresa a se adequar
                facilmente. Basta responder alguns formulários e nosso programa
                prepara os documentos necessários para você se adequar à LGPD.
              </FunctionText>
            </SwiperSlide>
          </Swiper>
        </FunctionTextContainer>
        <CellphoneMockup>
          <CellphoneContent src={bg}></CellphoneContent>
        </CellphoneMockup>
      </FunctionSection>

      <VideoSectionContainer>
        <VideoSectionContentContainer>
          <HeaderVideoSectionContainer>
            <HeaderVideoSection>
              Adeque sua empresa ou perca tempo e dinheiro.
            </HeaderVideoSection>
          </HeaderVideoSectionContainer>

          <VideoContainer>
            <VideoVideoVideo>
              <VideoSectionVideo
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/kbtYHMlzw0Y"
                title="LGPD. lock- Sua empresa de acordo com a LGPD em poucos cliques, sem que você entenda da Lei"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></VideoSectionVideo>
            </VideoVideoVideo>
          </VideoContainer>

          <CtaButton
            fontSize={window.innerWidth > 760 ? "20px" : "15px"}
            size={window.innerWidth > 760 ? "30%" : "90%"}
            target="blank"
            href="https://wa.me//555196846238?text=Ol%C3%A1!%20Venho%20atrav%C3%A9s%20do%20seu%20site%20e%20quero%20adequar%20minha%20empresa"
          >
            [ REGULARIZAR MINHA EMPRESA AGORA! ]
          </CtaButton>
        </VideoSectionContentContainer>
        <FloatingLogo delay="2s" right="10vw" rotation={"50deg"} src={SoLogo} />
        <FloatingLogo
          delay="15s"
          size="50px"
          left="16vw"
          rotation={"-50deg"}
          src={SoLogo}
        />
        <FloatingLogo
          delay="5s"
          size="80px"
          left="20vw"
          rotation={"50deg"}
          src={SoLogo}
        />
        <FloatingLogo
          delay="14s"
          size="120px"
          right="70vw"
          rotation={"50deg"}
          src={SoLogo}
        />
        <FloatingLogo
          delay="5s"
          size="60px"
          right="75vw"
          rotation={"75deg"}
          src={SoLogo}
        />
      </VideoSectionContainer>

      <FaqMainContainer>
        <FaqSectionContainer>
          <FaqHeaderSection>
            <JustLogo src={SoLogo}></JustLogo>
            <FaqHeader>F.A.Q</FaqHeader>
          </FaqHeaderSection>
          <FaqContainer>
            <QuestionContainer onClick={() => changeState(1)}>
              <Question>POR QUE EU PRECISO ME REGULARIZAR A LGPD? </Question>
              <Triangle></Triangle>
              {firstQuestion ? (
                <AnswerContainer
                  style={{ animation: "expand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Segundo a Lei Geral de Proteção de Dados (LGPD), todas as
                    empresas que lidam com dados pessoais e sensíveis precisam
                    garantir a segurança e privacidade dessas informações. O não
                    cumprimento das disposições da LGPD pode resultar em multas
                    significativas (de até 50 milhões de reais, dependendo do
                    faturamento bruto anual da empresa), danos à reputação da
                    empresa e de seus parceiros e processos judiciais.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Segundo a Lei Geral de Proteção de Dados (LGPD), todas as
                    empresas que lidam com dados pessoais e sensíveis precisam
                    garantir a segurança e privacidade dessas informações. O não
                    cumprimento das disposições da LGPD pode resultar em multas
                    significativas (de até 50 milhões de reais, dependendo do
                    faturamento bruto anual da empresa), danos à reputação da
                    empresa e de seus parceiros e processos judiciais.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(2)}>
              <Question>
                QUAIS OS BENEFÍCIOS DO LGPD LOCK PARA MINHA EMPRESA?{" "}
              </Question>
              <Triangle></Triangle>
              {secondQuestion ? (
                <AnswerContainer>
                  <Answer>
                    O LGPD LOCK traz mais segurança jurídica para a empresa,
                    permitindo uma gestão mais eficiente dos riscos relacionados
                    ao manuseio de dados. Além disso, promove um aumento no
                    padrão do serviço e da competitividade, tanto dentro quanto
                    fora do Brasil, ao demonstrar compromisso com a proteção da
                    privacidade dos clientes.
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    O LGPD LOCK traz mais segurança jurídica para a empresa,
                    permitindo uma gestão mais eficiente dos riscos relacionados
                    ao manuseio de dados. Além disso, promove um aumento no
                    padrão do serviço e da competitividade, tanto dentro quanto
                    fora do Brasil, ao demonstrar compromisso com a proteção da
                    privacidade dos clientes.
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(3)}>
              <Question>PARA QUEM É O LGPD LOCK? </Question>
              <Triangle></Triangle>
              {thirdQuestion ? (
                <AnswerContainer>
                  <Answer>
                    O LGPD LOCK é ideal para pequenas e médias empresas que
                    precisam se adequar à LGPD.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    O LGPD LOCK é ideal para pequenas e médias empresas que
                    precisam se adequar à LGPD.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(4)}>
              <Question>COMO O LGPD LOCK ME AJUDA? </Question>
              <Triangle></Triangle>
              {fourQuestion ? (
                <AnswerContainer>
                  <Answer>
                    O LGPD LOCK ajuda sua empresa a se adequar à LGPD de forma
                    eficiente e econômica. Com nossas soluções automatizadas,
                    você pode implementar políticas de privacidade, gerenciar
                    consentimentos, realizar auditorias de dados, e muito mais,
                    tudo em conformidade com as exigências da legislação.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    O LGPD LOCK ajuda sua empresa a se adequar à LGPD de forma
                    eficiente e econômica. Com nossas soluções automatizadas,
                    você pode implementar políticas de privacidade, gerenciar
                    consentimentos, realizar auditorias de dados, e muito mais,
                    tudo em conformidade com as exigências da legislação.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(5)}>
              <Question>
                VOU PRECISAR DE UMA EQUIPE ESPECIALIZADA EM LGPD?{" "}
              </Question>
              <Triangle></Triangle>
              {fiveQuestion ? (
                <AnswerContainer>
                  <Answer>
                    Não. O LGPD LOCK foi projetado para ser fácil de usar, mesmo
                    para empresas sem equipe dedicada de conformidade com a
                    LGPD. Nossa plataforma oferece recursos intuitivos e suporte
                    especializado para orientá-lo em cada etapa do processo de
                    adequação.{" "}
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Não. O LGPD LOCK foi projetado para ser fácil de usar, mesmo
                    para empresas sem equipe dedicada de conformidade com a
                    LGPD. Nossa plataforma oferece recursos intuitivos e suporte
                    especializado para orientá-lo em cada etapa do processo de
                    adequação.{" "}
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
            <QuestionContainer onClick={() => changeState(6)}>
              <Question>
                É NECESSÁRIO TER CONHECIMENTO PRÉVIO SOBRE A LGPD?{" "}
              </Question>
              <Triangle></Triangle>
              {sixQuestion ? (
                <AnswerContainer>
                  <Answer>
                    Não. Nosso serviço foi desenvolvido para ser acessível a
                    todos, independentemente do nível de conhecimento prévio
                    sobre a LGPD. Além disso, disponibilizamos uma sequência de
                    treinamento para sanar suas dúvidas.
                  </Answer>
                </AnswerContainer>
              ) : (
                <AnswerContainer
                  style={{ animation: "dexpand 0.5s ease-in-out forwards" }}
                >
                  <Answer>
                    Não. Nosso serviço foi desenvolvido para ser acessível a
                    todos, independentemente do nível de conhecimento prévio
                    sobre a LGPD. Além disso, disponibilizamos uma sequência de
                    treinamento para sanar suas dúvidas.
                  </Answer>
                </AnswerContainer>
              )}
            </QuestionContainer>
          </FaqContainer>
        </FaqSectionContainer>
        <CtaButton
          fontSize={window.innerWidth > 760 ? "20px" : "15px"}
          size={window.innerWidth > 760 ? "30%" : "90%"}
          style={{ backgroundColor: "#d341ff;" }}
          target="blank"
          href="https://wa.me//555196846238?text=Ol%C3%A1!%20Venho%20atrav%C3%A9s%20do%20seu%20site%20e%20quero%20adequar%20minha%20empresa"
        >
          {" "}
          [AINDA DÚVIDAS? FALE COM UM ESPECIALISTA!]
        </CtaButton>
      </FaqMainContainer>
    </MainContainer>
  );
};

export default LandingNew;
