import React from 'react';
import styled from 'styled-components';
import Navbar from '../../Navbar/Navbar';

const Container = styled.div``;

const Titulo = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 1% auto 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const GlossarioContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const RowContainer = styled.div`
  display: flex;
  justify-content: space-around;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px 0;
  border-radius: 15px;
  max-width: 60%;
  min-width: 60%;
  @media screen and (max-width: 760px) {
    max-width: 90%;
    min-width: 90%;
  }
`;
const QuestionContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 4px solid #5b0390;
  margin: 7px 0;
  padding: 0 15px;
`;
const Question = styled.p`
  font-weight: bold;
`;
const AnswerContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;
const Answer = styled.p`
  text-align: center;
`;

const Glossario = () => {
  return (
    <Container>
      <Navbar />
      <GlossarioContainer>
        <Titulo>Glossário LGPD</Titulo>

        <RowContainer>
          <QuestionContainer>
            <Question>Acesso Restrito</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Limitação do acesso aos dados pessoais a pessoas especificamente
              autorizadas.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>ANPD (Autoridade Nacional de Proteção de Dados)</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Órgão responsável por fiscalizar e garantir a execução da LGPD.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Anonimização</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Processo de tratamento de dados que remove ou modifica a
              informação de modo que o titular não possa ser identificado.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Avaliação de Impacto à Proteção de Dados (AIPD)</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Processo de análise, que deve ser feito pelo controlador, sobre
              como as operações de tratamento de dados pessoais afetam a
              proteção desses dados.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Base Legal</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A fundamentação jurídica que permite o tratamento de dados
              pessoais de acordo com a LGPD, como o consentimento do titular,
              cumprimento de obrigação legal, execução de políticas públicas,
              realização de estudos por órgão de pesquisa, execução de contrato
              e legítimo interesse.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Bloqueio</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Suspensão temporária de qualquer operação de tratamento, mantendo
              os dados pessoais ou os bancos de dados.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Comunicação de Dados</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Compartilhamento de dados pessoais com terceiros, excluindo-se as
              transferências de dados.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Consentimento</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A autorização livre, informada e inequívoca que o titular dos
              dados dá para que seus dados pessoais sejam tratados para uma
              finalidade específica.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Consentimento do Titular</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A manifestação livre, informada e inequívoca pela qual o titular
              concorda com o tratamento de seus dados pessoais para uma
              finalidade determinada.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Controlador</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A pessoa ou entidade que toma as decisões sobre o tratamento de
              dados pessoais.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Dados Anonimizados</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Dados que, após um processo de anonimização, não podem ser
              associados a um indivíduo.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Dados de Crianças e Adolescentes</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Dados pessoais de menores de 18 anos, que exigem consentimento
              específico e destacado de pelo menos um dos pais ou responsável
              legal.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Dados Pessoais</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Informações que identificam ou podem identificar uma pessoa, como
              nome, CPF, endereço, e-mail, etc.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Dados Sensíveis</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Dados pessoais sobre origem racial ou étnica, convicção religiosa,
              opinião política, filiação a sindicato ou a organização de caráter
              religioso, filosófico ou político, dado referente à saúde ou à
              vida sexual, dado genético ou biométrico.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Direitos do Titular</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Conjunto de direitos que a LGPD garante aos indivíduos, incluindo
              o direito de acesso, correção, exclusão, informação sobre
              compartilhamento com terceiros, etc.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Eliminação</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Exclusão de dados ou conjunto de dados armazenados em banco de
              dados, independentemente do procedimento empregado.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>
              Encarregado de Proteção de Dados (DPO – Data Protection Officer)
            </Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Pessoa indicada pela organização para atuar como canal de
              comunicação entre o controlador, os titulares dos dados e a ANPD.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Finalidade</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              O objetivo para o qual os dados pessoais são tratados.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Incidente de Segurança</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Qualquer evento que possa comprometer a segurança dos dados
              pessoais e afetar a privacidade dos titulares.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Legítimo Interesse</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Base legal para o tratamento de dados pessoais para fins
              legítimos, especificados pelo controlador, desde que não
              prevaleçam os direitos e liberdades fundamentais do titular.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>LGPD (Lei Geral de Proteção de Dados)</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A legislação brasileira que regula o tratamento de dados pessoais,
              garantindo a privacidade e proteção dos dados das pessoas.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Necessidade</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A limitação do tratamento ao mínimo necessário para realizar suas
              finalidades, com abrangência dos dados, tempo de conservação e
              formas de utilização.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Notificação de Incidente</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A obrigação do controlador de comunicar à ANPD e ao titular a
              ocorrência de incidente de segurança que possa acarretar risco ou
              dano relevante aos titulares.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Operador</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A pessoa ou entidade que realiza o tratamento dos dados pessoais
              em nome do controlador.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Portabilidade de Dados</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Direito do titular de receber os dados pessoais que a ele se
              referem e que tenha fornecido a um controlador, em formato
              estruturado, de uso comum e leitura automática, e de transmitir
              esses dados a outro controlador.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Princípios da LGPD</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Conjunto de diretrizes que regem o tratamento de dados pessoais,
              como finalidade, adequação, necessidade, livre acesso, qualidade
              dos dados, transparência, segurança, prevenção, não discriminação,
              responsabilização e prestação de contas.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Pseudonimização</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Processo que substitui a identidade do titular dos dados por um
              identificador, de forma que este não possa ser identificado sem
              informações adicionais.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>
              Relatório de Impacto à Proteção de Dados Pessoais
            </Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Documentação que o controlador deve preparar para avaliar os
              riscos relacionados ao tratamento de dados pessoais.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>
              Responsabilização e Prestação de Contas (Accountability)
            </Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Princípio que estabelece que o controlador deve tomar medidas
              eficazes e ser capaz de demonstrar o cumprimento das normas de
              proteção de dados.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Segurança da Informação</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Conjunto de medidas de proteção para garantir a confidencialidade,
              integridade e disponibilidade dos dados pessoais.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Titular dos Dados</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>A pessoa a quem os dados pessoais se referem.</Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Transparência</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Princípio que exige que as informações sobre o tratamento de dados
              pessoais sejam acessíveis e fáceis de entender para o titular dos
              dados.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Transferência Internacional de Dados</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              A transferência de dados pessoais para um país estrangeiro ou
              organismo internacional do qual o país é membro.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Tratamento de Dados</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Qualquer operação realizada com dados pessoais, como coleta,
              produção, recepção, classificação, utilização, acesso, reprodução,
              transmissão, distribuição, processamento, arquivamento,
              armazenamento, eliminação, avaliação ou controle da informação,
              modificação, comunicação, transferência, difusão ou extração.
            </Answer>
          </AnswerContainer>
        </RowContainer>

        <RowContainer>
          <QuestionContainer>
            <Question>Violação de Dados Pessoais</Question>
          </QuestionContainer>
          <AnswerContainer>
            <Answer>
              Uma quebra de segurança que cause, acidental ou ilicitamente, a
              destruição, perda, alteração, divulgação ou acesso não autorizados
              a dados pessoais.
            </Answer>
          </AnswerContainer>
        </RowContainer>
      </GlossarioContainer>
    </Container>
  );
};

export default Glossario;
