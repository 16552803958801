import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  border: 0.5px solid var(--borda-texto);
  padding: 9px 5px;
  margin: 10px;
  border-radius: 5px;
  min-width: 200px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  @media screen and (max-width: 960px) {
    min-width: auto;
    width: 100%;
    flex-direction: column;
  }
`;

const Rotulo = styled.label`
  color: var(--azul-escuro-titulo);
  font-family: "Montserrat", "sans-serif";
  font-weight: 400;
  font-size: 16px;
  padding: 9px 7px 0 5px;
  @media screen and (max-width: 960px) {
    width: 100%;
    font-size: 10px;
    text-align: center;
    padding: 0;
    margin-bottom: 10px;
    color: #be00ff;
    font-weight: 600;
  }
`;

const Select = styled.select`
  color: var(--cinza);
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  font-weight: 400;
  border-radius: 6px;
  padding: 10px;
  @media screen and (max-width: 960px) {
    min-width: auto;
    width: 100%;
    font-size: 9px;
    font-weight: 600;
  }
  @media screen and (max-width: 450px) {
    min-width: auto;
    width: 100%;
    font-size: 8px;
  }
`;

const Opcao = styled.option`
  color: var(--azul-escuro-titulo);
  font-family: "Montserrat", "sans-serif";
  font-size: 12px;
  font-weight: 400;
`;

const ListaSuspensa = (props) => {
  const opcoes = [0, 1, 2, 3, 4, 5];
  return (
    <Container>
      <Rotulo htmlFor={props.label}>{props.label}</Rotulo>
      <Select
        // defaultValue="Avalie Risco de 0 a 5"
        name={props.label}
        id={props.label}
        onChange={(e) => props.onChange(e)}
      >
        <Opcao value={0}>Avalie Risco de 0 a 5</Opcao>;
        {opcoes.map((opcao) => {
          return (
            <Opcao key={opcao} value={opcao}>
              {opcao}
            </Opcao>
          );
        })}
      </Select>
    </Container>
  );
};

export default ListaSuspensa;
