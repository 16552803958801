import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/firebase";
import { getAuth } from "firebase/auth";

import styled from "styled-components";
import Navbar from "../../Componentes/Navbar/Navbar";
import ResultadoPoliticaDePrivacidade from "../../Componentes/ResultadoPoliticaDePrivacidade";

const Container = styled.div`
  margin: 20px auto;
  /* max-width: 800px; */
  background-color: var(--branco);
  /* height: 100vh; */
  margin-bottom: 80px;
  padding-bottom: 40px;
`;

const DocumentoPoliticaDePrivacidade = () => {
  const [usuario, setUsuario] = useState(getAuth()?.currentUser?.uid);
  const [dados, setDados] = useState(null);
  const targetRef = useRef();

  const getDados = async () => {
    try {
      const docRef = doc(db, "usuarios", usuario);
      const docSnap = await getDoc(docRef);
      setDados(docSnap.data());
      if (docSnap.exists()) {
        setDados(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDados();
  }, [usuario]);

  return (
    <div>
      <Navbar />
      <div ref={targetRef}>
        <Container>
          {dados && <ResultadoPoliticaDePrivacidade dados={dados} />}
        </Container>
      </div>
    </div>
  );
};

export default DocumentoPoliticaDePrivacidade;
