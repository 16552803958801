import React, { useState } from "react";
import Navbar from "../../Componentes/Navbar/Navbar";
import styled from "styled-components";
import CampoCheckBox from "../../Componentes/CampoCheckBox";
import { perguntasTabelaDeDescarte as perguntas } from "../../Dados/PerguntasTabelaDeDescarte";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import CampoTexto from "../../Componentes/CampoTexto";
import perigo from "../../imgs/perigo.png";
import Perigo from "../../Componentes/Perigo";

const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
  @media screen and (max-width: 960px) {
    margin: 2% 4%;
    padding: 3%;
  }
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;

const TabelaDeDescarte = () => {
  const navigate = useNavigate();
  const docRef = collection(db, "usuarios");
  const [pergunta0, setPergunta0] = useState();
  const [pergunta1, setPergunta1] = useState();
  const [pergunta7, setPergunta7] = useState();
  const [pergunta8, setPergunta8] = useState();
  const [pergunta12, setPergunta12] = useState();
  const [pergunta13, setPergunta13] = useState();
  const [pergunta24, setPergunta24] = useState();
  const [pergunta25, setPergunta25] = useState();

  const [arrayDeRespostas, setArrayDeRespostas] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const adicionaResposta = (posicao) => {
    console.log(arrayDeRespostas);
    const updatedCheckedState = arrayDeRespostas.map((item, index) =>
      index === posicao ? !item : item
    );

    setArrayDeRespostas(updatedCheckedState);
  };

  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: pergunta0,
          [perguntas[1].codigo]: pergunta1,
          [perguntas[2].codigo]: arrayDeRespostas[2] ? "Sim" : "Não",
          [perguntas[3].codigo]: arrayDeRespostas[3] ? "Sim" : "Não",
          [perguntas[4].codigo]: arrayDeRespostas[4] ? "Sim" : "Não",
          [perguntas[5].codigo]: arrayDeRespostas[5] ? "Sim" : "Não",
          [perguntas[6].codigo]: arrayDeRespostas[6] ? "Sim" : "Não",
          [perguntas[7].codigo]: pergunta7,
          [perguntas[8].codigo]: pergunta8,
          [perguntas[9].codigo]: arrayDeRespostas[9] ? "Sim" : "Não",
          [perguntas[10].codigo]: arrayDeRespostas[10] ? "Sim" : "Não",
          [perguntas[11].codigo]: arrayDeRespostas[11] ? "Sim" : "Não",
          [perguntas[12].codigo]: pergunta12,
          [perguntas[13].codigo]: pergunta13,
          [perguntas[14].codigo]: arrayDeRespostas[14] ? "Sim" : "Não",
          [perguntas[15].codigo]: arrayDeRespostas[15] ? "Sim" : "Não",
          [perguntas[16].codigo]: arrayDeRespostas[16] ? "Sim" : "Não",
          [perguntas[17].codigo]: arrayDeRespostas[17] ? "Sim" : "Não",
          [perguntas[18].codigo]: arrayDeRespostas[18] ? "Sim" : "Não",
          [perguntas[19].codigo]: arrayDeRespostas[19] ? "Sim" : "Não",
          [perguntas[20].codigo]: arrayDeRespostas[20] ? "Sim" : "Não",
          [perguntas[21].codigo]: arrayDeRespostas[21] ? "Sim" : "Não",
          [perguntas[22].codigo]: arrayDeRespostas[22] ? "Sim" : "Não",
          [perguntas[23].codigo]: arrayDeRespostas[23] ? "Sim" : "Não",
          [perguntas[24].codigo]: pergunta24,
          [perguntas[25].codigo]: pergunta25,
          [perguntas[26].codigo]: arrayDeRespostas[26] ? "Sim" : "Não",
          [perguntas[27].codigo]: arrayDeRespostas[27] ? "Sim" : "Não",
          [perguntas[28].codigo]: arrayDeRespostas[28] ? "Sim" : "Não",
          [perguntas[29].codigo]: arrayDeRespostas[29] ? "Sim" : "Não",
          [perguntas[30].codigo]: arrayDeRespostas[30] ? "Sim" : "Não",
          [perguntas[31].codigo]: arrayDeRespostas[31] ? "Sim" : "Não",
          [perguntas[32].codigo]: arrayDeRespostas[32] ? "Sim" : "Não",
          [perguntas[33].codigo]: arrayDeRespostas[33] ? "Sim" : "Não",

          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      navigate("/documento-tabela-descarte", {
        state: getAuth().currentUser.uid,
      });
      // navigate('/documento');
      console.log("Cadastrar");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <Title>Tabela de descarte</Title>
      <form onSubmit={cadastrar}>
        <NeumorphismContainer>
          <SectionTitle>Dados de identificação</SectionTitle>
          {perguntas.map((item, index) => {
            if (index > 25 && index < 34) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}

          <CampoTexto
            required={true}
            label={perguntas[0].pergunta}
            placeholder={"Preencha seu nome completo"}
            onChange={(e) => setPergunta0(e.target.value)}
          />

          <CampoTexto
            required={true}
            label={perguntas[1].pergunta}
            onChange={(e) => setPergunta1(e.target.value)}
          />
        </NeumorphismContainer>
        <NeumorphismContainer>
          <SectionTitle>Dados financeiros</SectionTitle>
          {perguntas.map((item, index) => {
            if (index > 1 && index < 7) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}

          <CampoTexto
            required={true}
            label={perguntas[7].pergunta}
            onChange={(e) => setPergunta7(e.target.value)}
          />

          <CampoTexto
            required={true}
            label={perguntas[8].pergunta}
            onChange={(e) => setPergunta8(e.target.value)}
          />
        </NeumorphismContainer>
        <NeumorphismContainer>
          <SectionTitle>Hábitos pessoais</SectionTitle>
          {perguntas.map((item, index) => {
            if (index > 8 && index < 12) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}

          <CampoTexto
            required={true}
            label={perguntas[12].pergunta}
            onChange={(e) => setPergunta12(e.target.value)}
          />

          <CampoTexto
            required={true}
            label={perguntas[13].pergunta}
            onChange={(e) => setPergunta13(e.target.value)}
          />
        </NeumorphismContainer>
        <NeumorphismContainer>
          <SectionTitle>Dados sensíveis</SectionTitle>
          {perguntas.map((item, index) => {
            if (index > 13 && index < 24) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}

          <CampoTexto
            required={true}
            label={perguntas[24].pergunta}
            onChange={(e) => setPergunta24(e.target.value)}
          />

          <CampoTexto
            required={true}
            label={perguntas[25].pergunta}
            onChange={(e) => setPergunta25(e.target.value)}
          />
          <Perigo text="Dados físicos? Devem ser destruídos"></Perigo>
          <Perigo text="Dados digitais? Devem ser excluídos. Assim como todas suas cópias"></Perigo>
        </NeumorphismContainer>
        <ButtonContainer>
          <ButtonPattern>Enviar</ButtonPattern>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default TabelaDeDescarte;
