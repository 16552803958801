import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handlePreencherTela = () => {
    const message = createChatBotMessage(
      'Clique no botão abaixo para ser redirecionado para uma tela que reproduz a primeira tela do seu software. Selecione novamente o formulário que deseja preencher. Você vai encontrar um vídeo com a própria idealizadora do software (Joana Faccini Salaverry) preenchendo a tela com você.',
      {
        widget: 'opcoesPreencherTela',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleLGPD = () => {
    const botMessage = createChatBotMessage(
      'Aqui colocamos um dicionário LGPD. Explicamos para você - de uma forma simples- vários termos técnicos da Lei.',
      {
        widget: 'LGPD',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const handleOpcoesDuvidaTecnica = () => {
    const botMessage = createChatBotMessage(
      'Temos aqui algumas perguntas frequentes que costumam nos fazer. Clique aqui para ver se a sua dúvida já está entre as respondidas.',
      {
        widget: 'duvidaTecnica',
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handlePreencherTela,
            handleLGPD,
            handleOpcoesDuvidaTecnica,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
