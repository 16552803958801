import React, { useEffect, useState } from "react";
import Navbar from "../Componentes/Navbar/Navbar";
import "./Treinamento.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { videoArray } from "./videoArray";
import YouTube from "react-youtube";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../Firebase/firebase";
import ListaDeVideos from "../Componentes/ListaDeVideos";
import Progresso from "../Componentes/Progresso";

const Treinamentos = () => {
  const [usuario, setUsuario] = useState(getAuth()?.currentUser?.uid);
  const [dados, setDados] = useState({});
  const [dataSource, setDataSource] = useState(videoArray);
  const [videoIndex, setVideoIndex] = useState(0);
  const [percent, setPercent] = useState(0);
  const docRef = collection(db, "usuarios");
  const [totalViews, settotalViews] = useState();

  const changeIndex = (index) => {
    setVideoIndex(index);
  };

  useEffect(() => {
    getDados();
  }, []);

  const getDados = async () => {
    try {
      const docRef = doc(db, "usuarios", usuario);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDados(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
    // contaViews();
  };

  const contaViews = () => {
    const nomes = Object.keys(dados);
    const subArr = nomes.filter((str) => str.includes("video"));
    console.log(subArr.length);
    settotalViews(subArr.length);
    setPercent(Math.round((totalViews * 100) / 21));
  };

  useEffect(() => {
    setPercent(Math.round((totalViews * 100) / 21));
    contaViews();
    return () => {};
  }, [dados]);

  const resetProgress = () => {
    // videoArray.map((item, index) => {
    //   console.log(item);
    //   item.seen = false;
    //   item.teste = "teste";
    // });
    // percent = 0;
    // <Treinamentos />;
    setPercent(0);
  };
  let mobile = window.innerWidth < 960;
  const opts = {
    width: "100%",
    height: `${mobile ? "360" : "720"}`,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const cadastrar = async (id) => {
    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          [id]: true,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
    setDados((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    settotalViews((prevViews) => prevViews + 1);
  };

  const confereTempo = (e, id) => {
    const duration = e.target.getDuration();
    const currentTime = e.target.getCurrentTime();

    if (currentTime / duration > 0.95) {
      cadastrar(id);
    }
  };

  return (
    <div className="main-training-container">
      <Navbar />
      <div className="main-training-content">
        <div className="main-video-container">
          <p className="class-name">{dataSource[videoIndex].titulo}</p>
          <h5 className="playlist-name">Playlist de treinamentos</h5>

          {/* <img className="placeholder" src={placeholder} alt="placeholder" /> */}
          {/* <iframe
            width='100%'
            height='720'
            src={dataSource[videoIndex].videoUrl}
            title='Aula 3  Violação de dados'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen
          ></iframe> */}

          <YouTube
            videoId={dataSource[videoIndex].videoId}
            opts={opts}
            styles={opts}
            title={"Teste"}
            onEnd={(e) => confereTempo(e, dataSource[videoIndex].id)}
            // onStateChange={(e) => confereTempo(e, dataSource[videoIndex].id)}
          />

          <p className="class-info">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
            nisi iusto officia, porro nulla atque temporibus deserunt distinctio
            quae quis modi in conseuatur earum neque facilis vel dolorem, saepe
            excepturi?
          </p>
        </div>
        <div className="playlist-section">
          <div className="progress-section">
            {dados && <Progresso percent={percent} fn={contaViews} />}
            {/* <div className='progress-bar'>
              <p>Progresso de treinamento</p>

              <p>{percent}%</p>
            </div>
            <progress
              max={100}
              value={percent}
              className='classes-progress-bar'
            ></progress> */}
            <button
              onClick={() => resetProgress()}
              className="reset-progress-button"
            >
              REINICIAR PROGRESSO
            </button>
          </div>

          <div className="classes-main-container">
            <div>
              <InfiniteScroll
                endMessage={<p>Voce finalizou o treinamento!</p>}
                dataLength={dataSource.length}
                height={500}
              >
                {dados && dataSource && (
                  <ListaDeVideos
                    dados={dados}
                    fn={changeIndex}
                    dataSource={dataSource}
                  />
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treinamentos;
