import "./LandingPage.css";
import Logo from "../../imgs/idv images/logo-branco-info.png";
import Logo2 from "../../imgs/idv images/BRANCOstext.png";
import Polygon from "../../imgs/idv images/logo-branco-info.png";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SobreNos from "./SobreNos";
import ComoFunciona from "./ComoFunciona";
import Planos from "./Planos";
// import Footer from '../Componentes/Footer';

<link
  rel="stylesheet"
  href="LandingPage.css"
  media="screen and (max-width: 480px)"
/>;

const LandingPage = () => {
  const aboutUsRef = useRef(null);
  const comoFuncionaRef = useRef(null);
  const planosRef = useRef(null);
  const navigate = useNavigate();

  const hamburguer = () => {
    var menu = document.getElementById("menu");

    if (menu.style.display === "block") {
      menu.style.display = "none";
    } else {
      menu.style.display = "block";
    }
  };

  return (
    <div>
      <div className="body">
        <nav className="cabecalho">
          <div className="box">
            <img src={Logo2} className="logo-cabecalho" alt="Logo" />
            <ul className="lista">
              <li
                onClick={() => {
                  console.log("teste");
                  aboutUsRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Sobre nós
              </li>
              <li
                onClick={() => {
                  console.log("teste");
                  comoFuncionaRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Como funciona?
              </li>
              <li
                onClick={() => {
                  console.log("teste");
                  planosRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Planos
              </li>
              {/* <li>Clientes</li> */}
            </ul>
          </div>
          <div className="button--container">
            <button className="botao-contact">
              <a
                target="blank"
                href="https://wa.me//555196846238?text=Ol%C3%A1!%20Venho%20atrav%C3%A9s%20do%20seu%20site%20e%20quero%20adequar%20minha%20empresa"
                style={{ textDecoration: "none", color: "white" }}
              >
                Contrate agora
              </a>
            </button>
            <button onClick={() => navigate("/login")} className="botao-entrar">
              Entrar
            </button>
          </div>
        </nav>
        <div className="container">
          <div className="logo-text">
            <img src={Logo} className="logo" alt="logo" />
            <p className="text">
              Deixa a LGPD com a gente. <br /> Simplificamos tudo para que você
              possa focar no que mais importa: o seu negócio.
            </p>
          </div>
        </div>
        {/* <SobreNos /> */}

        {/* <Footer></Footer> */}
      </div>
      <SobreNos reference={aboutUsRef} />
      <ComoFunciona reference={comoFuncionaRef} />
      <Planos reference={planosRef} />
    </div>
  );
};

export default LandingPage;
