import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./Chatbot.css";
import config from "./config.js";
import MessageParser from "./MessageParser.jsx";
import ActionProvider from "./ActionProvider.jsx";
import { useState } from "react";
import { ConditionallyRender } from "react-util-kit";

import { ReactComponent as ButtonIcon } from "./robot.svg";

const ChatbotLGPD = () => {
  const [mostraChatbot, setMostraChatbot] = useState(false);

  return (
    <>
      <div className="app-chatbot-container">
        <ConditionallyRender
          ifTrue={mostraChatbot}
          show={
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          }
        />
      </div>
      <button onClick={() => setMostraChatbot((prev) => !prev)}>Suporte</button>
    </>
  );
};

export default ChatbotLGPD;
